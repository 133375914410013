import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "./components/Editor";
import SidebarActions from "./components/SidebarActions";

const EditComponent = (props) => {
  // ---------------------------------------------------------
  const [title, setTitle] = useState(props.editData.title);
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  // ---
  // Agent base 64
  const [agentFile, setAgentFile] = useState("");
  const [agentExtension, setAgentExtension] = useState("");
  const [feature, setFeature] = useState(props.editData.feature);

  const [phase, setPhase] = useState(props.editData.phase);
  const [size, setSize] = useState(props.editData.size);

  const [sector, setSector] = useState(props.editData.sector);
  const [sectors, setSectors] = useState([]);

  const [location, setLocation] = useState(props.editData.location);
  const [city, setCity] = useState(props.editData.city);
  const [price, setPrice] = useState(props.editData.price);
  const [description, setDescription] = useState(props.editData.description);
  const [category, setCategory] = useState(props.editData.category);
  const [details, setDetails] = useState(props.editData.details);
  const [agentImage, setAgentImage] = useState(props.editData.agentImage);
  const [agentDatatype, setAgentDatatype] = useState(
    props.editData.agentDatatype
  );
  const reset = () => {
    props.closeEdit();
    setTitle("");
    setFile("");
    setExtension("");
    setSize("");
    setLocation("");
    setCity("");
    setPhase("");
    setCategory("");
    setPrice("");
    setDescription("");
    setAgentFile("");
    setAgentExtension("");
  };

  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    fetch(
      "https://property.aiodock.com/fortune/plot/image/" + props.editData.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.editData.datatype + "base64," + response.data);
        setExtension(props.editData.image.split(".")[1]);
      });
      if (props.editData.details.image) {
        fetch(
          "https://property.aiodock.com/fortune/agent/agentimage/" +
            props.editData.details.image,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (res.status === 400) {
              alert("No Agent Found");
              // Handle other error cases if necessary
            } else if (res.ok) { // Check for a successful response
              return res.json();
            } else {
              throw new Error("Failed to fetch agent image");
            }
          })
          .then((response) => {
            setAgentFile(
              props.editData.details.datatype + "base64," + response.data
            );
            setAgentExtension(props.editData.details.image.split(".")[1]);
          })
          .catch((error) => {
            console.error("Error fetching agent image:", error);
            // Handle the error appropriately (e.g., show an error message)
          });
      }
      
    var obj = {
      phase: phase,
    };
    fetch("https://property.aiodock.com/fortune/sector/phaseHavingSectors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        setSectors(res.map((response) => response.sector));
      });
  }, []);

  return (
    <Row>
      {/* Editor */}
      <Col lg="9" md="12">
        <Editor
          title={title}
          setTitle={setTitle}
          details={details}
          setDetails={setDetails}
          sectors={sectors}
          setSectors={setSectors}
          sector={sector}
          setSector={setSector}
          phase={phase}
          setPhase={setPhase}
          setSize={setSize}
          size={size}
          feature={feature}
          setFeature={setFeature}
          setLocation={setLocation}
          location={location}
          setCity={setCity}
          city={city}
          setPrice={setPrice}
          price={price}
          setCategory={setCategory}
          category={category}
          setDescription={setDescription}
          description={description}
          setAgentDatatype={setAgentDatatype}
          setAgentImage={setAgentImage}
        />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <SidebarActions
          id={props.editData.id}
          details={details}
          phase={phase}
          sector={sector}
          title={title}
          size={size}
          location={location}
          feature={feature}
          city={city}
          price={price}
          category={category}
          description={description}
          reset={reset}
          file={file}
          extension={extension}
          agentFile={agentFile}
          agentExtension={agentExtension}
          datatypeAgent={agentDatatype}
          imageAgent={agentImage}
        />

        <br />
        <input
          className="form-control-alternative"
          id="contained-button-file"
          accept="image/*"
          type="file"
          onChange={(e) => {
            {
              handlePicture(e);
            }
          }}
        />
        <br />
        <strong> Plot Picture </strong>
        <br />
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${file})` }}
          ></div>
        </Card>
        {/* Agent File Upload */}
        <strong> Agent Picture</strong>
        <br />
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${agentFile})` }}
          ></div>
        </Card>
      </Col>
    </Row>
  );
};

export default EditComponent;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import Editor from "./components/Editor";
import SidebarActions from "./components/SidebarActions";

const EditComponent = (props) => {
  const [author, setAuthor] = useState(props.editData.author);
  const [title, setTitle] = useState(props.editData.title);
  const [file, setFile] = useState(props.editData.file);
  const [extension, setExtension] = useState(props.editData.extension);
  const [description, setDescription] = useState("");
  const [content, setContent] = useState(props.editData.content);

  const onChangeTitle = (text) => {
    setTitle(text);
  };
  const onChangeAuthor = (text) => {
    setAuthor(text);
  };
  const onChangeDescription = (text) => {
    setDescription(text);
  };

  const reset = () => {
    props.closeEdit();
    setTitle("");
    setFile("");
    setExtension("");
    setContent("");
  };

  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    fetch(
      "https://property.aiodock.com/fortune/blog/image/" + props.editData.image,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Blog Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.editData.datatype + "base64," + response.data);
        setExtension(props.editData.image.split(".")[1]);
      });
  }, []);

  return (
    <Row>
      {/* Editor */}
      <Col lg="9" md="12">
        <Editor
          title={title}
          author={author}
          content={content}
          onChangeTitle={onChangeTitle}
          onChangeAuthor={onChangeAuthor}
          setContent={setContent}
          onChangeDescription={onChangeDescription}
          short={true}
        />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <SidebarActions
          id={props.editData.id}
          content={content}
          title={title}
          file={file}
          extension={extension}
          reset={reset}
          author={author}
          description={description}
        />
        <br />
        <input
          className="form-control-alternative"
          id="contained-button-file"
          accept="image/*"
          type="file"
          onChange={(e) => {
            {
              handlePicture(e);
            }
          }}
        />
        <br />
        <br />
        <Card small className="card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${file})` }}
          ></div>
          {/* <h5 className="card-title">
              <a href="#" className="text-fiord-blue">
                {title}
              </a>
            </h5> */}
        </Card>
      </Col>
    </Row>
  );
};

export default EditComponent;

import { useRef, useState } from "react";
import {
  Typography,
  TextField,
  Alert,
  CssBaseline,
  Button,
  Snackbar,
  Grid,
  Box,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Propex from "../../components/Login2.jpg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://propex.pk">
        PropEx
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LogIn() {
  const [showPassword, setShowPassword] = useState(false);
  // SnackBar for handling success
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email === "info@propex.pk" && password === "fortune") {
      localStorage.setItem("user", "verified");
      setOpenSuccess(true);
      setTimeout(() => {
        window.location.href = "/add-new-plot";
      }, 1500);

    }
    else {
      setOpenError(true)
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const email = emailRef.current.value;
  //   const password = passwordRef.current.value;

  //   try {
  //     const response = await fetch("https://property.aiodock.com/fortune/login", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ email, password }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       const userToken = data.token;
  //       console.log("User token is ", userToken);

  //       localStorage.setItem("userToken", userToken);
  //       localStorage.setItem("user", "verified");
  //       setOpenSuccess(true);

  //       setTimeout(() => {
  //         window.location.href = "/add-new-plot";
  //       }, 1500);
  //     } else {
  //       setOpenError(true);
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setOpenError(true);
  //   }
  // };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: `url(${Propex})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            // backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={6} md={5} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h3" gutterBottom style={{ color: "#ff5a3c" }}>
              PropEx
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "rgb(100,55,100)" }}
            >
              Real Estate Managment Portal
            </Typography>
            <Typography variant="h5" gutterBottom style={{ color: "red" }}>
              Developed by AioDock
            </Typography>

            <Typography variant="h5" gutterBottom>
              Login
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                inputRef={emailRef}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                inputRef={passwordRef}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Snackbar
          open={openSuccess}
          autoHideDuration={1500}
          onClose={() => setOpenSuccess(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
            LogIn Successfully !
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={1500}
          onClose={() => setOpenError(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
            Invalid Credentials !
          </Alert>
        </Snackbar>
      </Grid>
    </ThemeProvider>
  );
}

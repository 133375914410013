import React, { useEffect, useState } from "react";
import {  Row, Col, Card, CardBody } from "shards-react";
import {
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  FormCheckbox,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

const Configure = () => {
  const [Slider, setSlider] = useState("");
  const [Banner, setBanner] = useState("");

  const [PropertyGhupshup, setPropertyGhupshup] = useState("");
  const [PropertyGhupshupTitle1, setPropertyGhupshupTitle1] = useState("");
  const [PropertyGhupshupDescription1, setPropertyGhupshupDescription1] =
    useState("");
  const [PropertyGhupshupURL1, setPropertyGhupshupURL1] = useState("");

  const [PropertyGhupshupTitle2, setPropertyGhupshupTitle2] = useState("");
  const [PropertyGhupshupDescription2, setPropertyGhupshupDescription2] =
    useState("");
  const [PropertyGhupshupURL2, setPropertyGhupshupURL2] = useState("");

  const [Residential1, setResidential1] = useState("");
  const [Residential2, setResidential2] = useState("");
  const [Residential3, setResidential3] = useState("");
  const [Residential4, setResidential4] = useState("");

  const [Commercial1, setCommercial1] = useState("");
  const [Commercial2, setCommercial2] = useState("");
  const [Commercial3, setCommercial3] = useState("");
  const [Commercial4, setCommercial4] = useState("");

  const [House1, setHouse1] = useState("");
  const [House2, setHouse2] = useState("");
  const [House3, setHouse3] = useState("");
  const [House4, setHouse4] = useState("");

  const [Headline1, setHeadline1] = useState("");
  const [Headline2, setHeadline2] = useState("");
  const [Headline3, setHeadline3] = useState("");

  const reset = () => {};
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/configure", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // setSlider(response[0].youtube.Silder);
        // setBanner(response[0].youtube.Banner);

        setPropertyGhupshup(response[0].youtube.PropertyGhupshup);
        setPropertyGhupshupTitle1(response[0].youtube.PropertyGhupshupTitle1);
        setPropertyGhupshupTitle2(response[0].youtube.PropertyGhupshupTitle2);
        setPropertyGhupshupDescription1(
          response[0].youtube.PropertyGhupshupDescription1
        );
        setPropertyGhupshupDescription2(
          response[0].youtube.PropertyGhupshupDescription2
        );
        setPropertyGhupshupURL1(response[0].youtube.PropertyGhupshupURL1);
        setPropertyGhupshupURL2(response[0].youtube.PropertyGhupshupURL2);

        setResidential1(response[0].youtube.Residential1);
        setResidential2(response[0].youtube.Residential2);
        setResidential3(response[0].youtube.Residential3);
        setResidential4(response[0].youtube.Residential4);

        setCommercial1(response[0].youtube.Commercial1);
        setCommercial2(response[0].youtube.Commercial2);
        setCommercial3(response[0].youtube.Commercial3);
        setCommercial4(response[0].youtube.Commercial4);

        setHouse1(response[0].youtube.House1);
        setHouse2(response[0].youtube.House2);
        setHouse3(response[0].youtube.House3);
        setHouse4(response[0].youtube.House4);
        // Headlines
        setHeadline1(response[0].youtube.Headline1);
        setHeadline2(response[0].youtube.Headline2);
        setHeadline3(response[0].youtube.Headline3);
      });
  }, []);

  return (
    <Row fluid className="main-content-Row px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Youtube Configuration"
          subtitle="Configuration"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Row>
            <Col md={6}>
              <b>Home Page Banner Video</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Home Page Banner Video"}
                value={Banner}
                type={"text"}
                onChange={(e) => {
                  setBanner(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>Property Ghushup</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Property Ghupshup Video"}
                value={PropertyGhupshup}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshup(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <b>Property Ghushup Video 1 Title</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 1 Title"}
                value={PropertyGhupshupTitle1}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupTitle1(e.target.value);
                }}
              />
              <b>Property Ghushup Video 1 URL</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 1 URL"}
                value={PropertyGhupshupURL1}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupURL1(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>Property Ghushup Video 1 Description</b>
              <FormTextarea
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 1 Description"}
                value={PropertyGhupshupDescription1}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupDescription1(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <b>Property Ghushup Video 2 Title</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 2 Title"}
                value={PropertyGhupshupTitle2}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupTitle2(e.target.value);
                }}
              />
              <b>Property Ghushup Video 2 URL</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 2 URL"}
                value={PropertyGhupshupURL2}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupURL2(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>Property Ghushup Video 2 Description</b>
              <FormTextarea
                size="lg"
                className="mb-3"
                placeholder={"Property Ghushup Video 2 Description"}
                value={PropertyGhupshupDescription2}
                type={"text"}
                onChange={(e) => {
                  setPropertyGhupshupDescription2(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <b>Residential Video 1</b>
              <FormInput
                size="lg"
                placeholder={"Residential Video 1"}
                value={Residential1}
                onChange={(e) => {
                  setResidential1(e.target.value);
                }}
              />
              <b>Residential Video 2</b>
              <FormInput
                size="lg"
                placeholder={"Residential Video 2"}
                value={Residential2}
                onChange={(e) => {
                  setResidential2(e.target.value);
                }}
              />
              <b>Residential Video 3</b>
              <FormInput
                size="lg"
                placeholder={"Residential Video 3"}
                value={Residential3}
                onChange={(e) => {
                  setResidential3(e.target.value);
                }}
              />
              <b>Residential Video 4</b>
              <FormInput
                size="lg"
                placeholder={"Residential Video 4"}
                value={Residential4}
                onChange={(e) => {
                  setResidential4(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>Commercial Video 1</b>
              <FormInput
                size="lg"
                placeholder={"Commercial Video 1"}
                value={Commercial1}
                onChange={(e) => {
                  setCommercial1(e.target.value);
                }}
              />
              <b>Commercial Video 2</b>
              <FormInput
                size="lg"
                placeholder={"Commercial Video 2"}
                value={Commercial2}
                onChange={(e) => {
                  setCommercial2(e.target.value);
                }}
              />
              <b>Commercial Video 3</b>
              <FormInput
                size="lg"
                placeholder={"Commercial Video 3"}
                value={Commercial3}
                onChange={(e) => {
                  setCommercial3(e.target.value);
                }}
              />
              <b>Commercial Video 4</b>
              <FormInput
                size="lg"
                placeholder={"Commercial Video 4"}
                value={Commercial4}
                onChange={(e) => {
                  setCommercial4(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Col md={6}>
              <b>House Video 1</b>
              <FormInput
                size="lg"
                placeholder={"House Video 1"}
                value={House1}
                onChange={(e) => {
                  setHouse1(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>House Video 2</b>
              <FormInput
                size="lg"
                placeholder={"House Video 2"}
                value={House2}
                onChange={(e) => {
                  setHouse2(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>House Video 3</b>
              <FormInput
                size="lg"
                placeholder={"House Video 3"}
                value={House3}
                onChange={(e) => {
                  setHouse3(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <b>House Video 4</b>
              <FormInput
                size="lg"
                placeholder={"House Video 4"}
                value={House4}
                onChange={(e) => {
                  setHouse4(e.target.value);
                }}
              />
            </Col>
          </Row>
          <hr style={{ border: "2px solid red" }} />
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Headlines" className="text-sm-left" />
          </Row>
          <Row>
            <Col md={12}>
              <b>Headline 01</b>
              <FormInput
                size="lg"
                placeholder={"Headline 01"}
                value={Headline1}
                onChange={(e) => {
                  setHeadline1(e.target.value);
                }}
              />
            </Col>
            <Col md={12}>
              <b>Headline 02</b>
              <FormInput
                size="lg"
                placeholder={"Headline 02"}
                value={Headline2}
                onChange={(e) => {
                  setHeadline2(e.target.value);
                }}
              />
            </Col>
            <Col md={12}>
              <b>Headline 03</b>
              <FormInput
                size="lg"
                placeholder={"Headline 03"}
                value={Headline3}
                onChange={(e) => {
                  setHeadline3(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            Silder={Slider}
            Banner={Banner}
            PropertyGhupshup={PropertyGhupshup}
            PropertyGhupshupTitle1={PropertyGhupshupTitle1}
            PropertyGhupshupTitle2={PropertyGhupshupTitle2}
            PropertyGhupshupDescription1={PropertyGhupshupDescription1}
            PropertyGhupshupDescription2={PropertyGhupshupDescription2}
            PropertyGhupshupURL1={PropertyGhupshupURL1}
            PropertyGhupshupURL2={PropertyGhupshupURL2}
            Residential1={Residential1}
            Residential2={Residential2}
            Residential3={Residential3}
            Residential4={Residential4}
            Commercial1={Commercial1}
            Commercial2={Commercial2}
            Commercial3={Commercial3}
            Commercial4={Commercial4}
            House1={House1}
            House2={House2}
            House3={House3}
            House4={House4}
            Headline1={Headline1}
            Headline2={Headline2}
            Headline3={Headline3}
            configure={true}
            reset={reset}
          />

          <br />
        </Col>
      </Row>
    </Row>
  );
};

export default Configure;

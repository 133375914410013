import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormInput } from "shards-react";

import SidebarActions from "../Agent/SidebarActions";

const EditComponent = (props) => {
  // ---------------------------------------------------------
  const [title, setTitle] = useState();
  const [name, setName] = useState(props.editData.name);
  const [agency, setAgency] = useState(props.editData.agency);
  const [phone, setPhone] = useState(props.editData.phone);

  // Agent base 64
  const [agentFile, setAgentFile] = useState("");
  const [agentExtension, setAgentExtension] = useState("");
  // ---

  const reset = () => {
    props.closeEdit();
    setTitle("");
    setAgentFile("");
    setAgentExtension("");
  };
  const handlePhoneChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");
    setPhone(newValue);
  };

  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setAgentExtension(ext);
    setAgentFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    fetch(
      "https://property.aiodock.com/fortune/agent/agentimage/" +
        props.editData.imageAgent,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setAgentFile(props.editData.datatypeAgent + "base64," + response.data);
        setAgentExtension(props.editData.imageAgent.split(".")[1]);
      });
  }, []);

  return (
    <Row>
     
      <Col lg={8} md={12}>
        <Row>
          <Col lg={4}>
            <b>Agent Name</b>
            <br />
            <FormInput
              value={name}
              size="lg"
              placeholder={"Image Title"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />{" "}
          </Col>
          <br />
          <Col lg={4}>
            <b>Agent Agency</b>
            <br />
            <FormInput
              value={agency}
              size="lg"
              placeholder={"Image Title"}
              onChange={(e) => {
                setAgency(e.target.value);
              }}
            />{" "}
          </Col>
          <br />
          <Col lg={4}>
            <b>Agent Phone</b>
            <br />
            <FormInput
              value={phone}
              size="lg"
              placeholder={"Phone Number"}
              onChange={handlePhoneChange}
            />{" "}
          </Col>
        </Row>
        <br />
        <Row>
          <input
            className="form-control-alternative"
            id="contained-button-file"
            accept="image/*"
            type="file"
            onChange={(e) => {
              {
                handlePicture(e);
              }
            }}
          />
          <br />
          <br />
          <Card small className="card-post card-post--1">
            <img
              src={agentFile}
              style={{
                height: "400px",
                // height:'100%',
                width: "100%",
                objectFit: "contain",
              }}
            />
            
          </Card>
        </Row>
      </Col>

      <Col lg={1} md={12}></Col>
      <Col lg={3} md={12}>
        <Row>
          <SidebarActions
            id={props.editData.id}
            name={name}
            agency={agency}
            phone={phone}
            agentFile={agentFile}
            agentExtension={agentExtension}
            reset={reset}
            agent={true}
          />
          <br />
        </Row>
      </Col>
    </Row>
  );
};

export default EditComponent;

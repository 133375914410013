import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  FormInput,
  CardBody,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Edit from "./WebsiteImages/EditImage";
import View from "./WebsiteImages/ViewImage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactLoading from "react-loading";
import { Alert } from "react-bootstrap";

const AddNewService = () => {
  const [file, setFile] = useState(null); // Updated state for the image file
  const [pageName, setPageName] = useState(""); // State for the page name
  const [extension, setExtension] = useState("");
  const [pages, setPages] = useState([]);
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading state

  const filter = () => {
    websiteImages();
  };
  useEffect(() => {
    websiteImages();
  }, []);

  const closeModal = () => {
    setEdit(false);
  };

  // Fetching Image Gallery Information
  const websiteImages = () => {
    fetch("https://property.aiodock.com/fortune/website", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setPages(response);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setExtension(file.name.split(".").pop());
  };

  // Function to upload the image
  const uploadImage = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("page", pageName);
    formData.append("file", file);

    fetch("https://property.aiodock.com/fortune/website", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        alert("Image Added Successfully!");
        // websiteImages();
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        alert("Image Upload Failed!");
        console.error(error);
      });
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {view ? (
        <View closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {/* <Col lg="1" md="1" /> */}
        <Col lg="9" md="9">
          <PageTitle
            sm="4"
            title="Background Images"
            subtitle="All Website Pages"
            className="text-sm-left"
          />
        </Col>
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="1" md="1" />
        <Col lg="10" md="10">
          <MaterialTable
            title="PropEx Web Pages"
            columns={[{ title: "Page Name", field: "page" }]}
            data={pages}
            options={{
              headerStyle: {
                backgroundColor: "#ff5a3c",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
              paging: false,
              // pageSize: 10,
              // pageSizeOptions: [20, 50, 100],
              search: true,
              exportButton: true,
            }}
            actions={[
              {
                icon: () => <VisibilityIcon style={{ color: "#c4183c" }} />,
                tooltip: "View",
                onClick: (event, rowData) => {
                  setEditData(rowData);
                  setView(true);
                },
              },
              {
                icon: () => <EditIcon style={{ color: "green" }} />,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setEditData(rowData);
                  setEdit(true);
                },
              },
              // {
              //   icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
              //   tooltip: "Delete",
              //   onClick: (event, rowData) => {
              //     if (window.confirm("Are you Sure? You want to delete!")) {
              //       fetch(
              //         "https://property.aiodock.com/fortune/website/delete",
              //         {
              //           method: "DELETE",
              //           headers: {
              //             "Content-Type": "application/json",
              //           },
              //           body: JSON.stringify({
              //             id: rowData._id,
              //           }),
              //         }
              //       ).then((res) => {
              //         if (res.status === 400) {
              //           alert("Failed, Server is not responding!");
              //         } else {
              //           alert("Success, Fortune Deleted!");
              //           filter();
              //         }
              //       });
              //     }
              //   },
              // },
            ]}
          />
        </Col>
        <Col lg="1" md="1" />
        {/* Sidebar Widgets */}
        {/* <Col lg="3" md="12">
          <SidebarActions
            // reset={reset}
            file={file}
            extension={extension}
            home={true}
            page={pageName}
          />
          <br />
          <b>Page Name</b>
          <FormInput
            fullWidth
            size="lg"
            className="mb-3"
            placeholder={"Page Name"}
            type={"text"}
            onChange={(e) => {
              setPageName(e.target.value);
            }}
          />

          <strong>Upload Picture</strong>
          <div>
            <input
              name="images"
              type="file"
              id="file"
              accept="image/*"
              onChange={handleFileChange}
              required
              multiple
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            {loading ? (
              <ReactLoading
                type="spin"
                color={"#ff5a3c"}
                height={"20%"}
                width={"20%"}
              />
            ) : (
              <Button
                onClick={uploadImage}
                style={{
                  backgroundColor: "#ff5a3c",
                  color: "#fff",
                  borderColor: "#ff5a3c",
                }}
                type="submit"
              >
                Upload
              </Button>
            )}
          </div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default AddNewService;

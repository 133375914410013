import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

const AddNewPost = () => {
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  const onChangeTitle = (text) => {
    setTitle(text);
  };
  const onChangeAuthor = (text) => {
    setAuthor(text);
  };
  const onChangeDescription = (text) => {
    setDescription(text);
  };
  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };
  const reset = () => {
    setTitle("");
    setFile("");
    setExtension("");
    setContent("");
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Blog"
          subtitle="Blog Posts"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Editor
            onChangeTitle={onChangeTitle}
            setContent={setContent}
            onChangeAuthor={onChangeAuthor}
            onChangeDescription={onChangeDescription}
            title={"Blog Title"}
          />
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            author={author}
            content={content}
            title={title}
            file={file}
            extension={extension}
            description={description}
            reset={reset}
          />
          <br />
          <input
            className="form-control-alternative"
            id="contained-button-file"
            accept="image/*"
            type="file"
            onChange={(e) => {
              {
                handlePicture(e);
              }
            }}
          />
          <br />
          <br />
          <Card small className="card-post card-post--1">
            <div
              className="card-post__image"
              style={{ backgroundImage: `url(${file})` }}
            ></div>
            <CardBody>
              {/* <h5 className="card-title">
                <a href="#" className="text-fiord-blue">
                  {title}
                </a>
              </h5> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewPost;

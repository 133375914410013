import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, FormInput, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Edit from "./Documents/EditDocument";
import View from "./Documents/ViewDocument";
import VisibilityIcon from "@mui/icons-material/Visibility";

const AddNewService = () => {
  const [file, setFile] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [extension, setExtension] = useState("");
  const [pages, setPages] = useState([]);
  // All Sizes
  const [sizes, setSizes] = useState([]);
  const reset = () => {
    setFile("");
    setExtension("");
  };
  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [editData, setEditData] = useState([]);

  const filter = () => {
    websiteImages();
  };
  useEffect(() => {
    websiteImages();
  }, []);

  const closeModal = () => {
    setEdit(false);
  };

  // Fetching Image Gallery Information
  const websiteImages = () => {
    fetch("https://property.aiodock.com/fortune/document", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setPages(response);
      });
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {view ? (
        <View closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {/* <Col lg="1" md="1" /> */}
        <Col lg="12" md="12">
          <PageTitle
            sm="4"
            title="Documents"
            // subtitle="All Website Pages"
            className="text-sm-left"
          />
        </Col>
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="9">
          <MaterialTable
            title="Documents"
            columns={[{ title: "Document Title", field: "title" }]}
            data={pages}
            options={{
              headerStyle: {
                backgroundColor: "#ff5a3c",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
              paging: false,
              // pageSize: 10,
              // pageSizeOptions: [20, 50, 100],
              search: true,
              exportButton: true,
            }}
            actions={[
              {
                icon: () => <VisibilityIcon style={{ color: "#c4183c" }} />,
                tooltip: "View",
                onClick: (event, rowData) => {
                  setEditData(rowData);
                  setView(true);
                },
              },
              {
                icon: () => <EditIcon style={{ color: "green" }} />,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setEditData(rowData);
                  setEdit(true);
                },
              },
              {
                icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  if (window.confirm("Are you Sure? You want to delete!")) {
                    fetch(
                      "https://property.aiodock.com/fortune/document/delete",
                      {
                        method: "DELETE",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          id: rowData.id,
                        }),
                      }
                    ).then((res) => {
                      if (res.status === 400) {
                        alert("Failed, Server is not responding!");
                      } else {
                        alert("Success, Fortune Deleted!");
                        filter();
                      }
                    });
                  }
                },
              },
            ]}
          />
        </Col>
        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            reset={reset}
            file={file}
            extension={extension}
            document={true}
            title={documentTitle}
          />
          <br />

          <Col>
            <b>Document Name</b>
            <FormInput
              fullWidth
              size="lg"
              className="mb-3"
              placeholder={"Page Name"}
              type={"text"}
              onChange={(e) => {
                setDocumentTitle(e.target.value);
              }}
            />
          </Col>
          <strong>Upload Picture</strong>
          <input
            className="form-control-alternative"
            id="contained-button-file"
            accept=".pdf"
            type="file"
            onChange={(e) => {
              handlePicture(e);
            }}
          />

          {/* <br />
          <Card small className="card-post card-post--1">
            <div
              className="card-post__image"
              style={{ backgroundImage: `url(${file})` }}
            ></div>
          </Card> */}
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewService;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormInput } from "shards-react";

import SidebarActions from "./SidebarActions";

const EditComponent = (props) => {
  // ---------------------------------------------------------

  // Agent base 64
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  // const [documentTitle, setDocumentTitle] = useState("");
  const [documentTitle, setDocumentTitle] = useState(props.editData.title);
  // ---

  const reset = () => {
    props.closeEdit();

    setFile("");
    setExtension("");
    setDocumentTitle("");
  };

  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/document/" + props.editData.pdf, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.editData.datatype + "base64," + response.data);
        setExtension(props.editData.image.split(".")[1]);
      });
  }, []);

  return (
    <Row>
      <Col lg={8} md={12}>
        <Col>
          <b>Document Name</b>
          {/* <FormInput
            fullWidth
            size="lg"
            className="mb-3"
            placeholder={"Document Name"}
            type={"text"}
            value={props.editData.title}
            onChange={(e) => {
              setDocumentTitle(e.target.value);
            }}
          /> */}
          <FormInput
            size="lg"
            placeholder={"Page Name"}
            value={documentTitle} // Use the documentTitle state for value
            onChange={(e) => {
              setDocumentTitle(e.target.value); // Update the documentTitle state
            }}
          />
        </Col>
        <br />
        <Row>
          <input
            className="form-control-alternative"
            id="contained-button-file"
            accept=".pdf"
            type="file"
            onChange={(e) => {
              {
                handlePicture(e);
              }
            }}
          />
          <iframe src={file} width="100%" height="400"></iframe>
        </Row>
      </Col>

      <Col lg={1} md={12}></Col>
      <Col lg={3} md={12}>
        <Row>
          <SidebarActions
            id={props.editData.id}
            file={file}
            extension={extension}
            reset={reset}
            title={documentTitle}
            agent={true}
          />
          <br />
        </Row>
      </Col>
    </Row>
  );
};

export default EditComponent;

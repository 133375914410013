import React, { useState } from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Row, FormInput, Container, Col } from "shards-react";
import {
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";

import InnerEditor from "./innerEditor";

import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";

const Editor = (props) => {
  return (
    <Card small className="mb-3">
      <CardBody>
        <Row>
          <Col md={6}>
            <b>Phase</b>
            <FormInput
              size="lg"
              placeholder={"Phase"}
              value={props.phase}
              onChange={(e) => {
                props.setPhase(e.target.value);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Sector</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.sector}
              placeholder={"Sector"}
              type={"text"}
              onChange={(e) => {
                props.setSector(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <b>Size</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.size}
              placeholder={"Size"}
              type={"text"}
              onChange={(e) => {
                props.setSize(e.target.value);
              }}
            />
          </Col>
          <br />
          <Col md={6}>
            <b>Rate</b>
            <FormInput
              size="lg"
              placeholder={"Rate"}
              value={props.rate}
              onChange={(e) => {
                props.setRate(e.target.value);
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Editor;

export default function () {
  return [
    {
      title: "Website Images",
      htmlBefore: '<i class="material-icons">home</i>',
      to: "/graphics",
    },

    // {
    //   title: "Maps",
    //   htmlBefore:  '<i class="material-icons">map</i>',
    //   to: "/maps",
    // },
    {
      title: "Add New Agent",
      htmlBefore: '<i class="material-icons">person_add</i>',
      to: "/agent",
    },
    {
      title: "Agent Directory",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/allAgents",
    },
    {
      title: "Photo Gallery",
      htmlBefore: '<i class="material-icons">photo</i>',
      to: "/photo-gallery",
    },
    {
      title: "Add New Property",
      htmlBefore: '<i class="material-icons">add</i>',
      to: "/add-new-plot",
    },
    {
      title: "All Properties",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/plots",
    },
    {
      title: "Add New Blog",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-blog",
    },
    {
      title: "All Blogs",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    // {
    //   title: "Add New Market Watch",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-marketwatch",
    // },
    // {
    //   title: "Market Watch",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/marketwatch",
    // },
    {
      title: "Documents",
      htmlBefore: '<i class="material-icons">upload_file</i>',
      to: "/documents",
    },
    {
      title: "Configuration",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/configure",
    },
    {
      title: "Sector Management",
      htmlBefore: '<i class="material-icons">add_chart</i>',
      to: "/sectormanagement",
    },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">logout</i>',
      to: "/logout",
    },
  ];
}

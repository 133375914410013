import React, { useState } from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewBlog";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import AddPlot from "./views/AddPlot";
import AddNewSubService from "./views/AddNewSubServices";
import SubServices from "./views/SubServices";
import Plots from "./views/Plots";
import AddMarketWatch from "./views/AddMarketWatch";
import MarketWatch from "./views/MarketWatch";
import Configure from "./views/Configure";
import SectorManagement from "./views/SectorManagement";
import UploadImage from "./views/imageGallery";
import AddAgents from "./views/Agent/CreateAgent";
import AgentDirectory from "./views/Agents";

import Home from "./views/WebsiteImages";
import Documents from "./views/Documents"
import Logout from "./views/LogIn&LogOut/SignOut";
import Login from "./views/LogIn&LogOut/SigninForm";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/add-new-plot" />,
  },
  {
    path: "/graphics",
    layout: DefaultLayout,
    component: Home,
  },
  {
    path: "/documents",
    layout: DefaultLayout,
    component: Documents,
  },

  {
    path: "/login",
    layout: DefaultLayout,
    component: Login,
  },
  {
    path: "/agent",
    layout: DefaultLayout,
    component: AddAgents,
  },
  {
    path: "/allAgents",
    layout: DefaultLayout,
    component: AgentDirectory,
  },
  {
    path: "/photo-gallery",
    layout: DefaultLayout,
    component: UploadImage,
  },
  {
    path: "/sectormanagement",
    layout: DefaultLayout,
    component: SectorManagement,
  },
  {
    path: "/add-new-blog",
    layout: DefaultLayout,
    component: AddNewPost,
  },
  {
    path: "/add-new-subservice",
    layout: DefaultLayout,
    component: AddNewSubService,
  },
  {
    path: "/add-new-plot",
    layout: DefaultLayout,
    component: AddPlot,
  },
  // {
  //   path: "/add-new-marketwatch",
  //   layout: DefaultLayout,
  //   component: AddMarketWatch,
  // },
  // {
  //   path: "/marketwatch",
  //   layout: DefaultLayout,
  //   component: MarketWatch,
  // },
  {
    path: "/plots",
    layout: DefaultLayout,
    component: Plots,
  },
  {
    path: "/sub-services",
    layout: DefaultLayout,
    component: SubServices,
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts,
  },
  {
    path: "/configure",
    layout: DefaultLayout,
    component: Configure,
  },
  {
    path: "/logout",
    layout: DefaultLayout,
    component: Logout,
  },
];

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import RangeDatePicker from "../components/common/RangeDatePicker";
import MaterialTable from "material-table";
import Edit from "./Blog/edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);

  from.setDate(from.getDate() - 7);

  useEffect(() => {
    var obj = {
      from,
      to,
    };

    fetch("https://property.aiodock.com/fortune/blog/filter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setBlog(response);
      });
  }, []);
  const closeModal = () => {
    setEdit(false);
  };
  const filter = () => {
    var obj = {
      from,
      to,
    };

    fetch("https://property.aiodock.com/fortune/blog/filter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setBlog(response);
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Manage Blogs"
          subtitle="Blog"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}

      <Row className="mb-4">
        <Col lg="2" md="2" sm="12"></Col>
        <Col lg="4" md="4" sm="12">
          {" "}
          <RangeDatePicker
            to={to}
            from={from}
            setFrom={setFrom}
            setTo={setTo}
          />
        </Col>
        <Col lg="4" md="4" sm="12">
          {" "}
          <Button
            outline
            theme="accent"
            style={{
              borderColor: "#ff5a3c",
              color: "#fff",
              backgroundColor: "#ff5a3c",
            }}
            size="sm"
            onClick={() => filter()}
          >
            <i className="material-icons">search</i> Search
          </Button>
        </Col>

        <Col lg="2" md="2" sm="12"></Col>
      </Row>

      <CardBody
        className="p-0 pb-3"
        style={{ height: "500px", overflow: "scroll" }}
      >
        <MaterialTable
          title="Blogs"
          columns={[
            {
              title: "Date",
              field: "date",
              defaultSort: "desc",
              render: (rowData) => new Date(rowData.date).toLocaleDateString(),
            },
            { title: "Author Name", field: "author" },
            { title: "Blog Title", field: "title" },
            { title: "Description", field: "description" },
            // { title: "Content", field: "content" },
          ]}
          data={blog}
          options={{
            headerStyle: {
              backgroundColor: "#ff5a3c",
              color: "#FFF",
            },
            actionsColumnIndex: -1,
            paging: false,
            // pageSize: 10,
            // pageSizeOptions: [20, 50, 100],
            search: true,
            exportButton: true,
          }}
          actions={[
            {
              icon: () => <EditIcon style={{ color: "green" }} />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setEditData(rowData);
                setEdit(true);
              },
            },
            {
              icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
              tooltip: "Delete",
              onClick: (event, rowData) => {
                if (window.confirm("Are you Sure? You want to delete!")) {
                  fetch("https://property.aiodock.com/fortune/blog/", {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: rowData.id,
                    }),
                  }).then((res) => {
                    if (res.status === 400) {
                      alert("Failed, Server is not responding!");
                    } else {
                      alert("Success, Fortune Deleted!");
                      filter();
                    }
                  });
                }
              },
            },
          ]}
        />
      </CardBody>
    </Container>
  );
};

export default Blog;

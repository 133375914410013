import React, { useState } from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput, Container, Col } from "shards-react";
import {
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

import InnerEditor from './innerEditor'

import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";

const Editor = (props) => {
  

  return <Card small className="mb-3">
    <CardBody>
 
      <Form className="add-new-post">
       
        <FormInput size="lg" className="mb-3" placeholder={"Sub Service Title"} value={props.title} onChange={(e)=>{props.onChangeTitle(e.target.value)}}/>
        <InnerEditor setContent={props.setContent} short={props.short} content={props.content} />
      </Form>
    </CardBody>
  </Card>
}

export default Editor;

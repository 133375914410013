import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import { FormInput } from "shards-react"; // Import FormInput

import PageTitle from "../../components/common/PageTitle";
import Editor from "../../components/add-new-post/Editor";
import SidebarActions from "../../components/add-new-post/SidebarActions";

const AddNewService = () => {
  const [name, setName] = useState("");
  const [agency, setAgency] = useState("");
  const [phone, setPhone] = useState("");

  // Agent base 64
  const [agentFile, setAgentFile] = useState("");
  const [agentExtension, setAgentExtension] = useState("");
  const [feature, setFeature] = useState(false);

  // All Sizes
  const [sizes, setSizes] = useState([]);

  const reset = () => {
    setName("");
    setAgency("");
    setPhone("");
    setAgentFile("");
  };

  const handleAgentPicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setAgentExtension(ext);
    setAgentFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePhoneChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");
    setPhone(newValue);
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Agent"
          subtitle="Agent Creation"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Card small className="mb-3">
            <CardBody>
              <Container>
                <Row>
                  <Col md={6} lg={6}>
                    <b>Agent Name</b>
                    <FormInput
                      size="lg"
                      placeholder={"Agent Name"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={6} lg={6}>
                    <b>Agent Agency</b>
                    <FormInput
                      size="lg"
                      placeholder={"Agent Agency"}
                      onChange={(e) => {
                        setAgency(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <b>Phone Number</b>
                    <FormInput
                      size="lg"
                      type="text"
                      placeholder="03XXYYYYYYY"
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
          {/* <Editor
            setName={setName}
            setAgency={setAgency}
            setPhone={setPhone}
            title={"Add New Agent"}
          /> */}
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            name={name}
            agency={agency}
            phone={phone}
            agentFile={agentFile}
            agentExtension={agentExtension}
            reset={reset}
            agent={true}
          />
          {/* Agent File Upload */}
          <strong>Upload Agent Picture</strong>
          <input
            className="form-control-alternative"
            id="contained-button-file"
            accept="image/*"
            type="file"
            onChange={(e) => {
              {
                handleAgentPicture(e);
              }
            }}
          />
          <div style={{ marginTop: "1em" }}>
            <Card small className="card-post card-post--1">
              <div
                className="card-post__image"
                style={{ backgroundImage: `url(${agentFile})` }}
              ></div>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewService;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormInput,
  Button,
} from "shards-react";
import ReactLoading from "react-loading";

const EditComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  // Function to handle image upload and update
// Function to handle image upload and update
const handleUpdateImage = () => {
  if (!selectedImage) {
    alert("Please select an image to update.");
    return;
  }

  // Extract the filename from the selected image
  const filename = selectedImage.name;

  // You can implement the image upload and update logic here.
  // Use a library like Axios or the Fetch API to send the image to your server.

  setLoading(true);

  // Example using the Fetch API to upload the image
  const formData = new FormData();
  formData.append("file", selectedImage);

  fetch(`https://property.aiodock.com/fortune/website/update/${props.editData.page}`, {
    method: "PUT",
    body: formData,
  })
    .then((response) => {
      if (response.status === 200) {
        // Image updated successfully
        alert(`Image '${filename}' updated successfully.`);
      } else {
        alert("Failed to update the image.");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      alert("An error occurred while updating the image.");
    })
    .finally(() => {
      setLoading(false);
    });
};


  return (
    <Row>
      <Col lg={8} md={12}>
        <br />
        <Row>
          <img
            src={`https://property.aiodock.com/fortune/website/${props.editData.image}`}
            style={{
              marginTop: "1em",
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Row>
      </Col>

      <Col lg={2} md={12}>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </Col>

      <Col lg={2} md={12}>
        <div style={{ marginTop: "1em" }}>
          {loading ? (
            <ReactLoading
              type="spin"
              color={"#ff5a3c"}
              height={"20%"}
              width={"20%"}
            />
          ) : (
            <Button
              onClick={handleUpdateImage}
              style={{
                backgroundColor: "#ff5a3c",
                color: "#fff",
                borderColor: "#ff5a3c",
              }}
              type="submit"
            >
              Update
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default EditComponent;

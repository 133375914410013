import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import {
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  FormCheckbox,
} from "shards-react";
import { CardHeader, Button } from "shards-react";

import SidebarActions from "./components/SidebarActions";

const SectorManagement = (props) => {
  const [phase, setPhase] = useState(props.editData.phase);
  const [sector, setSector] = useState(props.editData.sector);
  const [plot, setPlot] = useState([
    "1 Marla",
    "2 Marla",
    "3 Marla",
    "4 Marla",
    "5 Marla",
    "6 Marla",
    "7 Marla",
    "8 Marla",
    "9 Marla",
    "10 Marla",
    "11 Marla",
    "12 Marla",
    "13 Marla",
    "14 Marla",
    "15 Marla",
    "16 Marla",
    "17 Marla",
    "18 Marla",
    "19 Marla",
    "20 Marla",
    "1 Kanal",
    "2 Kanal",
    "3 Kanal",
    "4 Kanal",
    "5 Kanal",
  ]);
  const [selectedPlot, setSelectedPlot] = useState(props.editData.plot);
  const [selectAll, setSelectAll] = useState(false);
  const reset = () => {
    setPhase("");
    setSector("");
    setSelectedPlot([]);
    props.closeEdit();
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Row>
            <Col md={6}>
              <b>Phase</b>
              <FormSelect
                size="lg"
                value={phase}
                onChange={(e) => {
                  setPhase(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select the Phase
                </option>
                <option value="Phase 1">Phase 1</option>
                <option value="Phase 2">Phase 2</option>
                <option value="Phase 3">Phase 3</option>
                <option value="Phase 4">Phase 4</option>
                <option value="Phase 5">Phase 5</option>
                <option value="Phase 6">Phase 6</option>
                <option value="DHA Valley">DHA Valley</option>
              </FormSelect>
            </Col>
            <Col md={6}>
              <b>Sector</b>
              <FormInput
                size="lg"
                placeholder={"Sector"}
                value={sector}
                onChange={(e) => {
                  setSector(e.target.value);
                }}
              />
            </Col>
          </Row>
          <FormCheckbox
            checked={selectAll}
            onChange={(e) => {
              if (selectAll) {
                setSelectedPlot([]);
                setSelectAll(false);
              } else {
                setSelectedPlot([...plot]);
                setSelectAll(true);
              }
            }}
          >
            Select All
          </FormCheckbox>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) => (
              <Col md={2}>
                <FormCheckbox
                  checked={selectedPlot.includes(p)}
                  onChange={(e) => {
                    if (!selectedPlot.includes(p)) {
                      setSelectedPlot([...selectedPlot, p]);
                    } else {
                      setSelectedPlot(
                        selectedPlot.filter((plot) => plot !== p)
                      );
                    }
                  }}
                >
                  {p}
                </FormCheckbox>
              </Col>
            ))}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 5 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 11 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 17 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            id={props.editData.id}
            phase={phase}
            sector={sector}
            plot={selectedPlot}
            sectormanagement={true}
            reset={reset}
          />

          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default SectorManagement;

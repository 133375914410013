import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  FormSelect,
  Button,
  FormInput,
  CardBody,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Edit from "./Gallery/EditGallery";
import MaterialTable from "material-table";
import Select from "react-select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactLoading from "react-loading";

const AddNewService = () => {
  // const [title, setTitle] = useState("");
  // const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const [extension, setExtension] = useState("");
  // All Sizes
  const [imageGallery, setImageGallery] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [plotID, setPlotID] = useState([]);
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/plot", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setPlotID(response);
      });
  }, []);

  const reset = () => {
    setTitle("");
    setFile("");
    setExtension("");
  };
  const plotIDOptions = plotID.map((plot) => ({
    value: plot.id,
    label: `${plot.id} || ${plot.category}`,
  }));
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]); // Store multiple files in an array

  // const handleFileChange = (e) => {
  //   // Convert the FileList object to an array and store it in state
  //   const fileList = Array.from(e.target.files);
  //   setFiles(fileList);
  // };

  const handleFileChange = (e) => {
    const fileList = Array.from(e.target.files);
    const invalidFiles = fileList.filter((file) => file.size > 1024 * 1024); // Filter files with size greater than 1MB (1MB = 1024 * 1024 bytes)

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map((file) => file.name).join(", ");
      alert(
        `The following file(s) are too large and cannot be uploaded: ${invalidFileNames}. Maximum size allowed is 1MB.`
      );
      e.target.value = null;
      setFiles([]);
    } else {
      setFiles(fileList);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedOption) {
      alert("Please select a Plot ID.");
      setLoading(false);
      return;
    }

    if (!files.length) {
      alert("Please select one or more image files.");
      setLoading(false);
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    formData.append("plotId", selectedOption.value);
    formData.append("category", selectedOption.label);

    try {
      await fetch("https://property.aiodock.com/fortune/gallery", {
        method: "POST",
        body: formData,
      });
      alert("All images uploaded successfully");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images");
      setLoading(false);
    }
  };

  const filter = () => {
    fetchImageGallery();
  };
  useEffect(() => {
    fetchImageGallery();
  }, []);

  const closeModal = () => {
    setEdit(false);
  };

  const [plotIdCountArray, setPlotIdCountArray] = useState([]);
  // Fetching Image Gallery Information
  const fetchImageGallery = () => {
    fetch("https://property.aiodock.com/fortune/gallery")
      .then((response) => response.json())
      .then((data) => {

        setImageGallery(data);

        // Calculate plotIdData and plotIdCountArray here
        const plotIdData = {};

        // Populate the map with data
        data.forEach((item) => {
          const { plotId, category } = item;
          if (plotIdData[plotId]) {
            plotIdData[plotId].count++;
          } else {
            plotIdData[plotId] = {
              category,
              count: 1,
            };
          }
        });

        const calculatedPlotIdCountArray = Object.keys(plotIdData).map(
          (plotId) => ({
            plotId,
            category: plotIdData[plotId].category,
            count: plotIdData[plotId].count,
          })
        );

        setPlotIdCountArray(calculatedPlotIdCountArray);
      })
      .catch((error) => {
        console.error("Error fetching image data:", error);
      });
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Photo Gallery"
          subtitle="Manage Plot Images"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <CardBody className="p-0 pb-3">
            <MaterialTable
              title="Plot Gallery"
              columns={[
                { title: "Plot ID", field: "plotId" },
                { title: "Category", field: "category" },
                {
                  title: "No. of Images",
                  field: "count",
                },
              ]}
              data={plotIdCountArray}
              options={{
                headerStyle: {
                  backgroundColor: "#ff5a3c",
                  color: "#FFF",
                },
                actionsColumnIndex: -1,
                paging: false,
                // pageSize: 20,
                // pageSizeOptions: [20, 50, 100, 500],
                search: true,
                exportButton: true,
              }}
              actions={[
                {
                  icon: () => <VisibilityIcon style={{ color: "green" }} />,
                  tooltip: "View",
                  onClick: (event, rowData) => {
                    setEditData(rowData);
                    setEdit(true);
                  },
                },
                {
                  icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
                  tooltip: "Delete All",
                  onClick: (event, rowData) => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      fetch(
                        `https://property.aiodock.com/fortune/gallery/delete/${rowData.plotId}`,
                        {
                          method: "DELETE",
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            alert("Success, Fortune Deleted!");
                            filter();
                          } else if (res.status === 400) {
                            alert("Failed, Server is not responding!");
                          } else {
                            alert("Failed to delete Fortune item");
                          }
                        })
                        .catch((error) => {
                          console.error("Error deleting Fortune item:", error);
                        });
                    }
                  },
                },
              ]}
            />{" "}
          </CardBody>
        </Col>

        <Col lg="3" md="12">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <b>Select Plot ID</b>
            <Select
              size="lg"
              name="plotId"
              value={selectedOption}
              onChange={handleSelectChange}
              options={plotIDOptions}
              placeholder="Select the ID"
            />

            <strong>Upload Plot Picture</strong>
            <div>
              <input
                name="images"
                type="file"
                id="file"
                accept="image/*"
                onChange={handleFileChange}
                required
                multiple
              />
            </div>
            <div style={{ marginTop: "1em" }}>
              {loading ? (
                <ReactLoading
                  type="spin"
                  color={"#ff5a3c"}
                  height={"20%"}
                  width={"20%"}
                />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#ff5a3c",
                    color: "#fff",
                    borderColor: "#ff5a3c",
                  }}
                  type="submit"
                >
                  Upload
                </Button>
              )}
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewService;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

const AddNewService = () => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");

  // Agent base 64
  const [agentFile, setAgentFile] = useState("");
  const [agentExtension, setAgentExtension] = useState("");
  const [feature, setFeature] = useState(false);

  const [size, setSize] = useState("");
  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [sectors, setSectors] = useState([]);
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [details, setDetails] = useState({});
  const [agentImage, setAgentImage] = useState("");
  const [agentDatatype, setAgentDatatype] = useState("");
  const [image, setImage] = useState("");

  // All Sizes
  const [sizes, setSizes] = useState([]);

  const reset = () => {
    setTitle("");
    setFile("");
    setExtension("");
    setSize("");
    setLocation("");
    setCity("");
    setCategory("");
    setPhase("");
    setPrice("");
    setDescription("");
    setAgentFile("");
  };

  const handlePicture = async (event) => {
    const file = event.target.files[0];
    var fileInput = document.getElementById("contained-button-file");
    var filePath = fileInput.value;
    var fileArr = filePath.split(".");

    var ext = fileArr[fileArr.length - 1];

    const base64 = await convertBase64(file);
    setExtension(ext);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // useEffect(() => {
  //   fetch(
  //     "https://property.aiodock.com/fortune/agent/agentimage/" +
  //     agentImage,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       if (res.status === 400) {
  //         alert("No Plot Found");
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then((response) => {
  //       setImage(agentDatatype + "base64," + response.data);
  //     });
  // }, []);

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Property"
          subtitle="Property Creation"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Editor
            setTitle={setTitle}
            phase={phase}
            sizes={sizes}
            setSizes={setSizes}
            category={category}
            details={details}
            setDetails={setDetails}
            setSize={setSize}
            setSectors={setSectors}
            sectors={sectors}
            setPhase={setPhase}
            setSector={setSector}
            title={"Add New Plot"}
            setFeature={setFeature}
            feature={feature}
            setLocation={setLocation}
            setCity={setCity}
            setPrice={setPrice}
            setCategory={setCategory}
            setDescription={setDescription}
            price={price}
            setAgentDatatype={setAgentDatatype}
            setAgentImage={setAgentImage}
          />
        </Col>
        <Col lg="3" md="12">
        {/* Plot File Upload */}
        <strong>Upload Plot Picture</strong>
        <input
          className="form-control-alternative"
          id="contained-button-file"
          accept="image/*"
          type="file"
          onChange={(e) => {
            {
              handlePicture(e);
            }
          }}
        />

        <Card small className="mb-3 card-post card-post--1">
          <div
            className="card-post__image"
            style={{ backgroundImage: `url(${file})` }}
          ></div>
        </Card>
        {/* Sidebar Widgets */}
        
          <SidebarActions
            title={title}
            details={details}
            phase={phase}
            size={size}
            sector={sector}
            location={location}
            city={city}
            feature={feature}
            price={price}
            category={category}
            description={description}
            reset={reset}
            file={file}
            extension={extension}
            datatypeAgent={agentDatatype}
            imageAgent={agentImage}
            // agentFile={agentFile}
            // agentExtension={agentExtension}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewService;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Button,
  FormSelect,
  FormInput,
} from "shards-react";
import MaterialTable from "material-table";
import PageTitle from "../components/common/PageTitle";
import RangeDatePicker from "../components/common/RangeDatePicker";
import Edit from "./Plot/edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Services = () => {
  const [services, setServices] = useState([]);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [phaseFilter, setPhaseFilter] = useState("");
  const [sectorFilter, setSectorFilter] = useState("");

  // from.setDate(from.getDate() - 7);
  useEffect(() => {
    // Calculate the from date as one week ago
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    // Set the initial from and to dates
    setFrom(oneWeekAgo);
    setTo(currentDate);

    // Fetch data for the last week
    fetchServices(oneWeekAgo, currentDate);
  }, []);

  const fetchServices = (startDate, endDate) => {
    var obj = {
      from: startDate,
      to: endDate,
    };

    fetch("https://property.aiodock.com/fortune/plot/filter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setServices(response);
      });
  };

  const closeModal = () => {
    setEdit(false);
  };
  const [dateFilterPreset, setDateFilterPreset] = useState("");
  const handlePhaseChange = (e) => {
    setPhaseFilter(e.target.value);
  };

  const handleSectorChange = (e) => {
    setSectorFilter(e.target.value);
  };

  const filter = () => {
    fetchServices(from, to);
  };

  const handleFilterPresetChange = (preset) => {
    setDateFilterPreset(preset);
    const currentDate = new Date();
    let newFrom = new Date();
    switch (preset) {
      case "lastWeek":
        newFrom.setDate(currentDate.getDate() - 7);
        break;
      case "lastMonth":
        newFrom.setMonth(currentDate.getMonth() - 1);
        break;
      case "lastYear":
        newFrom.setFullYear(currentDate.getFullYear() - 1);
        break;
      case "allProperties":
        newFrom = new Date(2023, 0, 1);
        break;
      default:
        break;
    }
    setFrom(newFrom);
    setTo(currentDate);
    filter();
  };

  return (
    <Container fluid className="main-content-container px-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}

      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="All Properties"
          subtitle="Manage Properties"
          className="text-sm-left"
        />
      </Row>

      <Row className="mb-4">
        <Col lg="2" md="2" sm="12">
          <b>Select Date Filter</b>
          <FormSelect
            value={dateFilterPreset} // Bind the value to the state
            onChange={(e) => handleFilterPresetChange(e.target.value)}
          >
            <option value="">Select Filter</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="lastYear">Last Year</option>
            <option value="allProperties">All Properties</option>
          </FormSelect>
        </Col>

        <Col lg="3" md="3" sm="12">
          <b>Search between Dates</b>
          <RangeDatePicker
            to={to}
            from={from}
            setFrom={setFrom}
            setTo={setTo}
            disabled={!!dateFilterPreset}
          />
        </Col>
        <Col lg="1" md="1" sm="12" className="mt-4">
          <Button
            outline
            style={{
              borderColor: "#ff5a3c",
              color: "#fff",
              backgroundColor: "#ff5a3c",
            }}
            theme="accent"
            size="sm"
            onClick={() => filter()}
          >
            {/* <i className="material-icons">search</i>  */}
            Search
          </Button>
        </Col>
        <Col lg="3" md="3" sm="12">
          <b>Search by Phase</b>
          <FormInput
            type="text"
            id="phaseInput"
            onChange={handlePhaseChange}
            placeholder="Search by Phase"
          />
        </Col>
        <Col lg="3" md="3" sm="12">
          <b>Search by Sector</b>
          <FormInput
            type="text"
            id="sectorInput"
            onChange={handleSectorChange}
            placeholder="Search by Sector"
          />
        </Col>
        {/* <Col lg="3" md="3" sm="12">
          <b>Search by Phase</b>
          <FormInput
            type="text"
            id="myInput"
            onChange={(e) => {
              var input, filter, table, tr, td, i, txtValue;

              filter = e.target.value;
              table = document.getElementById("plotTable");
              tr = table.getElementsByTagName("tr");
              for (i = 0; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td")[2];
                if (td) {
                  txtValue = td.textContent || td.innerText;
                  if (
                    txtValue.toUpperCase().indexOf(filter.toUpperCase()) > -1
                  ) {
                    tr[i].style.display = "";
                  } else {
                    tr[i].style.display = "none";
                  }
                }
              }
            }}
            placeholder="Search by Phase"
          />
        </Col> 
        <Col lg="3" md="3" sm="12">
          <b>Search by Sector</b>
          <FormInput
            type="text"
            id="myInput"
            onChange={(e) => {
              var input, filter, table, tr, td, i, txtValue;

              filter = e.target.value;
              table = document.getElementById("plotTable");
              tr = table.getElementsByTagName("tr");
              for (i = 0; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td")[3];
                if (td) {
                  txtValue = td.textContent || td.innerText;
                  if (
                    txtValue.toUpperCase().indexOf(filter.toUpperCase()) > -1
                  ) {
                    tr[i].style.display = "";
                  } else {
                    tr[i].style.display = "none";
                  }
                }
              }
            }}
            placeholder="Search by Sector"
          />
        </Col> */}
        <Col lg="1" md="1" sm="12"></Col>
      </Row>

      <CardBody className="p-0 pb-3">
        <div id="plotTable">
          <MaterialTable
            title="Properties"
            columns={[
              {
                title: "Date",
                field: "date",
                defaultSort: "desc",
                render: (rowData) =>
                  new Date(rowData.date).toLocaleDateString(),
              },
              { title: "Plot ID", field: "id" },
              { title: "Phase", field: "phase", filtering: true },
              { title: "Sector", field: "sector" },
              { title: "Plot No.", field: "details.plotNo" },
              { title: "Street No.", field: "details.streetTitle" },
              { title: "Size", field: "size" },
              {
                title: "Price",
                field: "price",
                render: (rowData) =>
                  rowData.price ? rowData.price : rowData.title,
              },
              {
                title: "Type",
                field: "category",
                render: (rowData) => {
                  return rowData.category == "residential"
                    ? "Residential"
                    : rowData.category == "commercial"
                    ? "Commercial"
                    : rowData.category == "houses"
                    ? "Houses"
                    : rowData.category == "housesForSale"
                    ? "Sale Houses"
                    : rowData.category == "housesForRent"
                    ? "Rental Houses"
                    : rowData.category == "flatsForSale"
                    ? "Sale Flats"
                    : "Rental Flats";
                },
              },
              {
                title: "Category",
                // field: "details.category",
                render: (rowData) => {
                  return rowData.details.category === "Others"
                    ? rowData.details.customCategory
                    : rowData.details.category;
                },
              },
              {
                title: "Agent Name",
                field: "details.agentName",
              },
            ]}
            data={services.filter((rowData) => {
              const phaseMatch =
                !phaseFilter ||
                rowData.phase.toLowerCase().includes(phaseFilter.toLowerCase());
              const sectorMatch =
                !sectorFilter ||
                rowData.sector
                  .toLowerCase()
                  .includes(sectorFilter.toLowerCase());

              return phaseMatch && sectorMatch;
            })}
            options={{
              headerStyle: {
                backgroundColor: "#ff5a3c",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
              paging: false,
              // pageSize: 20,
              // pageSizeOptions: [50, 100, 500],
              search: true,
              exportButton: true,
              sort: true,
            }}
            actions={[
              {
                icon: () => <EditIcon style={{ color: "green" }} />,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  setEditData(rowData);
                  setEdit(true);
                },
              },
              {
                icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  if (window.confirm("Are you Sure? You want to delete!")) {
                    fetch("https://property.aiodock.com/fortune/plot", {
                      method: "DELETE",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        id: rowData.id,
                      }),
                    }).then((res) => {
                      if (res.status === 400) {
                        alert("Failed, Server is not responding!");
                      } else {
                        alert("Success, Fortune Deleted!");
                        filter();
                      }
                    });
                  }
                },
              },
            ]}
          />
        </div>
      </CardBody>
    </Container>
  );
};

export default Services;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import {
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  FormCheckbox,
} from "shards-react";
import MaterialTable from "material-table";
import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";

import Edit from "./Sector/edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const SectorManagement = () => {
  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [plot, setPlot] = useState([
    "1 Marla",
    "2 Marla",
    "3 Marla",
    "4 Marla",
    "5 Marla",
    "6 Marla",
    "7 Marla",
    "8 Marla",
    "9 Marla",
    "10 Marla",
    "11 Marla",
    "12 Marla",
    "13 Marla",
    "14 Marla",
    "15 Marla",
    "16 Marla",
    "17 Marla",
    "18 Marla",
    "19 Marla",
    "20 Marla",
    "1 Kanal",
    "2 Kanal",
    "3 Kanal",
    "4 Kanal",
    "5 Kanal",
  ]);
  const [selectedPlot, setSelectedPlot] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [editData, setEditData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/sector", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setRows(response);
      });
  }, []);
  function myFunction() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  const filter = () => {
    fetch("https://property.aiodock.com/fortune/sector", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setRows(response);
      });
  };

  const reset = () => {
    setPhase("");
    setSector("");
    setSelectedPlot([]);
    filter();
  };
  const closeModal = () => {
    setEdit(false);
    setEditData({});
  };

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      {edit ? <Edit editData={editData} closeModal={closeModal} /> : null}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Sector Management"
          subtitle="Add Sectors and Assign Plots"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="9" md="12">
          <Row>
            <Col md={6}>
              <b>Phase</b>
              <FormSelect
                size="lg"
                value={phase}
                onChange={(e) => {
                  setPhase(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select the Phase
                </option>
                <option value="Phase 1">Phase 1</option>
                <option value="Phase 2">Phase 2</option>
                <option value="Phase 3">Phase 3</option>
                <option value="Phase 4">Phase 4</option>
                <option value="Phase 5">Phase 5</option>
                <option value="Phase 6">Phase 6</option>
                <option value="DHA Valley">DHA Valley</option>
              </FormSelect>
            </Col>
            <Col md={6}>
              <b>Sector</b>
              <FormInput
                size="lg"
                placeholder={"Sector"}
                value={sector}
                onChange={(e) => {
                  setSector(e.target.value);
                }}
              />
            </Col>
          </Row>
          <FormCheckbox
            checked={selectAll}
            onChange={(e) => {
              if (selectAll) {
                setSelectedPlot([]);
                setSelectAll(false);
              } else {
                setSelectedPlot([...plot]);
                setSelectAll(true);
              }
            }}
          >
            Select All
          </FormCheckbox>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) => (
              <Col md={2}>
                <FormCheckbox
                  checked={selectedPlot.includes(p)}
                  onChange={(e) => {
                    if (!selectedPlot.includes(p)) {
                      setSelectedPlot([...selectedPlot, p]);
                    } else {
                      setSelectedPlot(
                        selectedPlot.filter((plot) => plot !== p)
                      );
                    }
                  }}
                >
                  {p}
                </FormCheckbox>
              </Col>
            ))}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 5 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 11 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              overflow: "hidden",
            }}
          >
            {plot.map((p, index) =>
              index > 17 ? (
                <Col md={2}>
                  <FormCheckbox
                    checked={selectedPlot.includes(p)}
                    onChange={(e) => {
                      if (!selectedPlot.includes(p)) {
                        setSelectedPlot([...selectedPlot, p]);
                      } else {
                        setSelectedPlot(
                          selectedPlot.filter((plot) => plot !== p)
                        );
                      }
                    }}
                  >
                    {p}
                  </FormCheckbox>
                </Col>
              ) : null
            )}
          </Container>
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <SidebarActions
            phase={phase}
            sector={sector}
            plot={selectedPlot}
            sectormanagement={true}
            reset={reset}
          />

          <br />
        </Col>
      </Row>

      <Row>
        <Col>
          <CardBody className="p-0 pb-3">
            <MaterialTable
              title="Sector Management"
              columns={[
                { title: "Phase", field: "phase" },
                { title: "Sector", field: "sector" },
              ]}
              data={rows}
              options={{
                headerStyle: {
                  backgroundColor: "#ff5a3c",
                  color: "#FFF",
                },
                actionsColumnIndex: -1,
                paging: false,
                // pageSize: 10,
                // pageSizeOptions: [20, 50, 100],
                search: true,
                exportButton: true,
              }}
              actions={[
                {
                  icon: () => <EditIcon style={{ color: "green" }} />,
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    setEditData(rowData);
                    setEdit(true);
                  },
                  iconButtonProps: {
                    style: {
                      color: "blue",
                    },
                  },
                },
                {
                  icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    if (window.confirm("Are you Sure? You want to delete!")) {
                      fetch("https://property.aiodock.com/fortune/sector", {
                        method: "DELETE",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          id: rowData.id,
                        }),
                      }).then((res) => {
                        if (res.status === 400) {
                          alert("Failed, Server is not responding!");
                        } else {
                          alert("Success, Fortune Deleted!");
                          filter();
                        }
                      });
                    }
                  },
                 
                },
              ]}
            />
          </CardBody>
        </Col>
      </Row>
    </Container>
  );
};

export default SectorManagement;

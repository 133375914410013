import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import {
  Card,
  CardBody,
  Form,
  FormInput,
  Container,
  Col,
  FormSelect,
  FormTextarea,
  FormCheckbox,
  Row,
} from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";
import PageTitle from "../../../components/common/PageTitle";

const Editor = (props) => {
  // console.log("Props are", props.sector);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [agent, setAgent] = useState([]);
  const [agency, setAgentAgency] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/agent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setAgent(response);
      });
  }, []);
  return (
    <Card small className="mb-3">
      <CardBody>
        <Row>
          <Col md={4}>
            <b>Price</b>
            <FormInput
              size="lg"
              className="mb-3"
              placeholder={"Price"}
              value={props.price}
              onChange={(e) => {
                props.setPrice(e.target.value);
              }}
            />
          </Col>
          <Col md={4}>
            <b>Location</b>
            <FormInput
              size="lg"
              className="mb-3"
              placeholder={"Location"}
              value={props.location}
              onChange={(e) => {
                props.setLocation(e.target.value);
              }}
            />
          </Col>
          <Col md={4} className="">
            <b>Select Property Type</b>
            <FormSelect
              size="lg"
              value={props.category}
              onChange={(e) => {
                props.setCategory(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select the Type
              </option>
              <option value="residential">Residential Plots</option>
              <option value="commercial">Commercial Plots</option>
              <option value="housesForSale">Houses For Sale</option>
              <option value="housesForRent">Houses For Rent</option>
              <option value="flatsForSale">Flats For Sale</option>
              <option value="flatsForRent">Flats For Rent</option>
            </FormSelect>
          </Col>
          <Col md={4}>
            <b>Phase</b>
            <FormSelect
              size="lg"
              value={props.phase}
              onChange={(e) => {
                props.setPhase(e.target.value);
                var obj = {
                  phase: e.target.value,
                };
                fetch(
                  "https://fortune.aiodock.com/fortune/sector/phaseHavingSectors",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                  }
                )
                  .then((res) => res.json())
                  .then((res) => {
                    props.setSectors(res.map((response) => response.sector));
                  });
              }}
            >
              <option value="" disabled selected>
                Select the Phase
              </option>
              <option value="Phase 1">Phase 1</option>
              <option value="Phase 2">Phase 2</option>
              <option value="Phase 3">Phase 3</option>
              <option value="Phase 4">Phase 4</option>
              <option value="Phase 5">Phase 5</option>
              <option value="Phase 6">Phase 6</option>
              <option value="DHA Valley">DHA Valley</option>
            </FormSelect>
          </Col>
          {props.category === "flatsForRent" ||
          props.category === "flatsForSale" ? (
            <>
              <Col md={4}>
                <b>Project by</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  value={props.details && props.details.projectBy}
                  placeholder={"Project by"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      projectBy: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Block</b>
                <FormSelect
                  size="lg"
                  value={props.sector}
                  onChange={(e) => {
                    props.setSector(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Block
                  </option>
                  <option value="Block A">Block A</option>
                  <option value="Block B">Block B</option>
                  <option value="Block C">Block C</option>
                  <option value="Block D">Block D</option>
                  <option value="Block E">Block E</option>
                  <option value="Block F">Block F</option>
                  <option value="Block G">Block G</option>
                  <option value="Block H">Block H</option>
                  <option value="Block I">Block I</option>
                  <option value="Block J">Block J</option>
                  <option value="Block K">Block K</option>
                  <option value="Block L">Block L</option>
                  <option value="Block M">Block M</option>
                  <option value="Block N">Block N</option>
                  <option value="Block O">Block O</option>
                  <option value="Block P">Block P</option>
                  <option value="Block Q">Block Q</option>
                  <option value="Block R">Block R</option>
                  <option value="Block S">Block S</option>
                  <option value="Block U">Block T</option>
                  <option value="Block U">Block U</option>
                  <option value="Block V">Block V</option>
                  <option value="Block W">Block W</option>
                  <option value="Block X">Block X</option>
                  <option value="Block Y">Block Y</option>
                  <option value="Block Z">Block Z</option>
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Flat Size</b>
                <FormInput
                  size="lg"
                  value={props.size}
                  className="mb-3"
                  placeholder={"Flat Size"}
                  type={"text"}
                  onChange={(e) => {
                    props.setSize(e.target.value);
                  }}
                />
              </Col>
            </>
          ) : (
            <>
              <Col md={4}>
                <b>Sector</b>
                <FormSelect
                  size="lg"
                  value={props.sector}
                  onChange={(e) => {
                    props.setSector(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Sector
                  </option>
                  {props.sectors.map((sect) => {
                    return <option value={sect}>{sect}</option>;
                  })}
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Size</b>
                <FormSelect
                  size="lg"
                  value={props.size}
                  onChange={(e) => {
                    props.setSize(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Plot Size
                  </option>
                  <option value="1 Marla">1 Marla</option>
                  <option value="2 Marla">2 Marla</option>
                  <option value="3 Marla">3 Marla</option>
                  <option value="4 Marla">4 Marla</option>
                  <option value="5 Marla">5 Marla</option>
                  <option value="6 Marla">6 Marla</option>
                  <option value="7 Marla">7 Marla</option>
                  <option value="8 Marla">8 Marla</option>
                  <option value="9 Marla">9 Marla</option>
                  <option value="10 Marla">10 Marla</option>
                  <option value="11 Marla">11 Marla</option>
                  <option value="12 Marla">12 Marla</option>
                  <option value="13 Marla">13 Marla</option>
                  <option value="14 Marla">14 Marla</option>
                  <option value="15 Marla">15 Marla</option>
                  <option value="16 Marla">16 Marla</option>
                  <option value="17 Marla">17 Marla</option>
                  <option value="18 Marla">18 Marla</option>
                  <option value="19 Marla">19 Marla</option>
                  <option value="20 Marla">20 Marla</option>

                  <option value="1 Kanal">1 Kanal</option>
                  <option value="2 Kanal">2 Kanal</option>
                  <option value="3 Kanal">3 Kanal</option>
                  <option value="4 Kanal">4 Kanal</option>
                  <option value="5 Kanal">5 Kanal</option>
                </FormSelect>
              </Col>
            </>
          )}
          {/* <Col md={4}>
            <b>Sector</b>
            <FormSelect
              size="lg"
              value={props.sector}
              onChange={(e) => {
                props.setSector(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select the Sector
              </option>
              {props.sectors.map((sect) => {
                return <option value={sect}>{sect}</option>;
              })}
            </FormSelect>
          </Col> */}
          {/* <Col md={4}>
            <b>Size</b>
            <FormSelect
              size="lg"
              value={props.size}
              onChange={(e) => {
                props.setSize(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select the Plot Size
              </option>
              <option value="1 Marla">1 Marla</option>
              <option value="2 Marla">2 Marla</option>
              <option value="3 Marla">3 Marla</option>
              <option value="4 Marla">4 Marla</option>
              <option value="5 Marla">5 Marla</option>
              <option value="6 Marla">6 Marla</option>
              <option value="7 Marla">7 Marla</option>
              <option value="8 Marla">8 Marla</option>
              <option value="9 Marla">9 Marla</option>
              <option value="10 Marla">10 Marla</option>
              <option value="11 Marla">11 Marla</option>
              <option value="12 Marla">12 Marla</option>
              <option value="13 Marla">13 Marla</option>
              <option value="14 Marla">14 Marla</option>
              <option value="15 Marla">15 Marla</option>
              <option value="16 Marla">16 Marla</option>
              <option value="17 Marla">17 Marla</option>
              <option value="18 Marla">18 Marla</option>
              <option value="19 Marla">19 Marla</option>
              <option value="20 Marla">20 Marla</option>

              <option value="1 Kanal">1 Kanal</option>
              <option value="2 Kanal">2 Kanal</option>
              <option value="3 Kanal">3 Kanal</option>
              <option value="4 Kanal">4 Kanal</option>
              <option value="5 Kanal">5 Kanal</option>
            </FormSelect>
          </Col> */}

          <Col md={4}>
            <b>City</b>
            <FormSelect
              size="lg"
              value={props.city}
              onChange={(e) => {
                props.setCity(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Select The City
              </option>
              <option value="Islamabad">Islamabad</option>
              <option value="Rawalpindi">Rawalpindi</option>
              <option value="Lahore">Lahore</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Multan">Multan</option>
              <option value="Quetta">Quetta</option>
              <option value="Karachi">Karachi</option>
              <option value="" disabled>
                Punjab Cities
              </option>
              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
              <option value="Ahmadpur East">Ahmadpur East</option>
              <option value="Ali Khan Abad">Ali Khan Abad</option>
              <option value="Alipur">Alipur</option>
              <option value="Arifwala">Arifwala</option>
              <option value="Attock">Attock</option>
              <option value="Bhera">Bhera</option>
              <option value="Bhalwal">Bhalwal</option>
              <option value="Bahawalnagar">Bahawalnagar</option>
              <option value="Bahawalpur">Bahawalpur</option>
              <option value="Bhakkar">Bhakkar</option>
              <option value="Burewala">Burewala</option>
              <option value="Chillianwala">Chillianwala</option>
              <option value="Chakwal">Chakwal</option>
              <option value="Chichawatni">Chichawatni</option>
              <option value="Chiniot">Chiniot</option>
              <option value="Chishtian">Chishtian</option>
              <option value="Daska">Daska</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
              <option value="Dhaular">Dhaular</option>
              <option value="Dina">Dina</option>
              <option value="Dinga">Dinga</option>
              <option value="Dipalpur">Dipalpur</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Ferozewala">Ferozewala</option>
              <option value="Fateh Jhang">Fateh Jang</option>
              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
              <option value="Gojra">Gojra</option>
              <option value="Gujranwala">Gujranwala</option>
              <option value="Gujrat">Gujrat</option>
              <option value="Gujar Khan">Gujar Khan</option>
              <option value="Hafizabad">Hafizabad</option>
              <option value="Haroonabad">Haroonabad</option>
              <option value="Hasilpur">Hasilpur</option>
              <option value="Haveli Lakha">Haveli Lakha</option>
              <option value="Jatoi">Jatoi</option>
              <option value="Jalalpur">Jalalpur</option>
              <option value="Jattan">Jattan</option>
              <option value="Jampur">Jampur</option>
              <option value="Jaranwala">Jaranwala</option>
              <option value="Jhang">Jhang</option>
              <option value="Jhelum">Jhelum</option>
              <option value="Kalabagh">Kalabagh</option>
              <option value="Karor Lal Esan">Karor Lal Esan</option>
              <option value="Kasur">Kasur</option>
              <option value="Kamalia">Kamalia</option>
              <option value="Kamoke">Kamoke</option>
              <option value="Khanewal">Khanewal</option>
              <option value="Khanpur">Khanpur</option>
              <option value="Kharian">Kharian</option>
              <option value="Khushab">Khushab</option>
              <option value="Kot Addu">Kot Addu</option>
              <option value="Jauharabad">Jauharabad</option>

              <option value="Lalamusa">Lalamusa</option>
              <option value="Layyah">Layyah</option>
              <option value="Liaquat Pur">Liaquat Pur</option>
              <option value="Lodhran">Lodhran</option>
              <option value="Malakwal">Malakwal</option>
              <option value="Mamoori">Mamoori</option>
              <option value="Mailsi">Mailsi</option>
              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
              <option value="Mian Channu">Mian Channu</option>
              <option value="Mianwali">Mianwali</option>
              <option value="Murree">Murree</option>
              <option value="Muridke">Muridke</option>
              <option value="Mianwali Bangla">Mianwali Bangla</option>
              <option value="Muzaffargarh">Muzaffargarh</option>
              <option value="Narowal">Narowal</option>
              <option value="Nankana Sahib">Nankana Sahib</option>
              <option value="Okara">Okara</option>
              <option value="Renala Khurd">Renala Khurd</option>
              <option value="Pakpattan">Pakpattan</option>
              <option value="Pattoki">Pattoki</option>
              <option value="Pir Mahal">Pir Mahal</option>
              <option value="Qaimpur">Qaimpur</option>
              <option value="Qila Didar Singh">Qila Didar Singh</option>
              <option value="Rabwah">Rabwah</option>
              <option value="Raiwind">Raiwind</option>
              <option value="Rajanpur">Rajanpur</option>
              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
              <option value="Sadiqabad">Sadiqabad</option>
              <option value="Safdarabad">Safdarabad</option>
              <option value="Sahiwal">Sahiwal</option>
              <option value="Sangla Hill">Sangla Hill</option>
              <option value="Sarai Alamgir">Sarai Alamgir</option>
              <option value="Sargodha">Sargodha</option>
              <option value="Shakargarh">Shakargarh</option>
              <option value="Sheikhupura">Sheikhupura</option>
              <option value="Sialkot">Sialkot</option>
              <option value="Sohawa">Sohawa</option>
              <option value="Soianwala">Soianwala</option>
              <option value="Siranwali">Siranwali</option>
              <option value="Talagang">Talagang</option>
              <option value="Taxila">Taxila</option>
              <option value="Toba Tek Singh">Toba Tek Singh</option>
              <option value="Vehari">Vehari</option>
              <option value="Wah Cantonment">Wah Cantonment</option>
              <option value="Wazirabad">Wazirabad</option>
              <option value="" disabled>
                Sindh Cities
              </option>
              <option value="Badin">Badin</option>
              <option value="Bhirkan">Bhirkan</option>
              <option value="Rajo Khanani">Rajo Khanani</option>
              <option value="Chak">Chak</option>
              <option value="Dadu">Dadu</option>
              <option value="Digri">Digri</option>
              <option value="Diplo">Diplo</option>
              <option value="Dokri">Dokri</option>
              <option value="Ghotki">Ghotki</option>
              <option value="Haala">Haala</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Islamkot">Islamkot</option>
              <option value="Jacobabad">Jacobabad</option>
              <option value="Jamshoro">Jamshoro</option>
              <option value="Jungshahi">Jungshahi</option>
              <option value="Kandhkot">Kandhkot</option>
              <option value="Kandiaro">Kandiaro</option>
              <option value="Kashmore">Kashmore</option>
              <option value="Keti Bandar">Keti Bandar</option>
              <option value="Khairpur">Khairpur</option>
              <option value="Kotri">Kotri</option>
              <option value="Larkana">Larkana</option>
              <option value="Matiari">Matiari</option>
              <option value="Mehar">Mehar</option>
              <option value="Mirpur Khas">Mirpur Khas</option>
              <option value="Mithani">Mithani</option>
              <option value="Mithi">Mithi</option>
              <option value="Mehrabpur">Mehrabpur</option>
              <option value="Moro">Moro</option>
              <option value="Nagarparkar">Nagarparkar</option>
              <option value="Naudero">Naudero</option>
              <option value="Naushahro Feroze">Naushahro Feroze</option>
              <option value="Naushara">Naushara</option>
              <option value="Nawabshah">Nawabshah</option>
              <option value="Nazimabad">Nazimabad</option>
              <option value="Qambar">Qambar</option>
              <option value="Qasimabad">Qasimabad</option>
              <option value="Ranipur">Ranipur</option>
              <option value="Ratodero">Ratodero</option>
              <option value="Rohri">Rohri</option>
              <option value="Sakrand">Sakrand</option>
              <option value="Sanghar">Sanghar</option>
              <option value="Shahbandar">Shahbandar</option>
              <option value="Shahdadkot">Shahdadkot</option>
              <option value="Shahdadpur">Shahdadpur</option>
              <option value="Shahpur Chakar">Shahpur Chakar</option>
              <option value="Shikarpaur">Shikarpaur</option>
              <option value="Sukkur">Sukkur</option>
              <option value="Tangwani">Tangwani</option>
              <option value="Tando Adam Khan">Tando Adam Khan</option>
              <option value="Tando Allahyar">Tando Allahyar</option>
              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
              <option value="Thatta">Thatta</option>
              <option value="Umerkot">Umerkot</option>
              <option value="Warah">Warah</option>
              <option value="" disabled>
                Khyber Cities
              </option>
              <option value="Abbottabad">Abbottabad</option>
              <option value="Adezai">Adezai</option>
              <option value="Alpuri">Alpuri</option>
              <option value="Akora Khattak">Akora Khattak</option>
              <option value="Ayubia">Ayubia</option>
              <option value="Banda Daud Shah">Banda Daud Shah</option>
              <option value="Bannu">Bannu</option>
              <option value="Batkhela">Batkhela</option>
              <option value="Battagram">Battagram</option>
              <option value="Birote">Birote</option>
              <option value="Chakdara">Chakdara</option>
              <option value="Charsadda">Charsadda</option>
              <option value="Chitral">Chitral</option>
              <option value="Daggar">Daggar</option>
              <option value="Dargai">Dargai</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ismail Khan">Dera Ismail Khan</option>
              <option value="Doaba">Doaba</option>
              <option value="Dir">Dir</option>
              <option value="Drosh">Drosh</option>
              <option value="Hangu">Hangu</option>
              <option value="Haripur">Haripur</option>
              <option value="Karak">Karak</option>
              <option value="Kohat">Kohat</option>
              <option value="Kulachi">Kulachi</option>
              <option value="Lakki Marwat">Lakki Marwat</option>
              <option value="Latamber">Latamber</option>
              <option value="Madyan">Madyan</option>
              <option value="Mansehra">Mansehra</option>
              <option value="Mardan">Mardan</option>
              <option value="Mastuj">Mastuj</option>
              <option value="Mingora">Mingora</option>
              <option value="Nowshera">Nowshera</option>
              <option value="Paharpur">Paharpur</option>
              <option value="Pabbi">Pabbi</option>
              <option value="Saidu Sharif">Saidu Sharif</option>
              <option value="Shorkot">Shorkot</option>
              <option value="Shewa Adda">Shewa Adda</option>
              <option value="Swabi">Swabi</option>
              <option value="Swat">Swat</option>
              <option value="Tangi">Tangi</option>
              <option value="Tank">Tank</option>
              <option value="Thall">Thall</option>
              <option value="Timergara">Timergara</option>
              <option value="Tordher">Tordher</option>
              <option value="" disabled>
                Balochistan Cities
              </option>
              <option value="Awaran">Awaran</option>
              <option value="Barkhan">Barkhan</option>
              <option value="Chagai">Chagai</option>
              <option value="Dera Bugti">Dera Bugti</option>
              <option value="Gwadar">Gwadar</option>
              <option value="Harnai">Harnai</option>
              <option value="Jafarabad">Jafarabad</option>
              <option value="Jhal Magsi">Jhal Magsi</option>
              <option value="Kacchi">Kacchi</option>
              <option value="Kalat">Kalat</option>
              <option value="Kech">Kech</option>
              <option value="Kharan">Kharan</option>
              <option value="Khuzdar">Khuzdar</option>
              <option value="Killa Abdullah">Killa Abdullah</option>
              <option value="Killa Saifullah">Killa Saifullah</option>
              <option value="Kohlu">Kohlu</option>
              <option value="Lasbela">Lasbela</option>
              <option value="Lehri">Lehri</option>
              <option value="Loralai">Loralai</option>
              <option value="Mastung">Mastung</option>
              <option value="Musakhel">Musakhel</option>
              <option value="Nasirabad">Nasirabad</option>
              <option value="Nushki">Nushki</option>
              <option value="Panjgur">Panjgur</option>
              <option value="Pishin Valley">Pishin Valley</option>
              <option value="Sherani">Sherani</option>
              <option value="Sibi">Sibi</option>
              <option value="Sohbatpur">Sohbatpur</option>
              <option value="Washuk">Washuk</option>
              <option value="Zhob">Zhob</option>
              <option value="Ziarat">Ziarat</option>
            </FormSelect>
          </Col>

          <Col md={4} className="mt-3">
            <FormCheckbox
              checked={props.feature}
              onChange={(e) => {
                props.setFeature(!props.feature);
              }}
            >
              <span style={{ marginLeft: "0.5em" }}>Show in Feature List</span>
            </FormCheckbox>
          </Col>
        </Row>
        {props.category == "residential" || props.category == "commercial" ? (
          <>
            <hr style={{ borderTop: "2px solid red" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle
                title={
                  props.category === "residential"
                    ? "Residential Plot Details"
                    : "Commercial Plot Details"
                }
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={4}>
                <b>Plot No.</b>
                <FormInput
                  value={props.details && props.details.plotNo}
                  size="lg"
                  className="mb-3"
                  placeholder={"Plot No"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      plotNo: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
                <FormCheckbox
                  checked={props.details && props.details.showPlotNo}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      showPlotNo: !props.details.showPlotNo,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <span style={{ marginLeft: "0.5em" }}>Show Plot Number</span>
                </FormCheckbox>
              </Col>
              <Col md={4}>
                <b>Street No.</b>
                <FormInput
                  value={props.details && props.details.streetTitle}
                  size="lg"
                  className="mb-3"
                  placeholder={"Street"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      streetTitle: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Extra Land</b>
                <FormInput
                  value={props.details && props.details.extraland}
                  size="lg"
                  className="mb-3"
                  placeholder={"Extra Land"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      extraland: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Category</b>
                <FormSelect
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      category: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.category}
                >
                  <option value="" disabled selected>
                    Select the Category
                  </option>
                  <option value="General">General</option>
                  <option value="Corner">Corner</option>
                  <option value="Sun Facing">Sun Facing</option>
                  <option value="Park Facing">Park Facing</option>
                  <option value="Boulevard Avenue">Boulevard Avenue</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.category == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Category"}
                    type={"text"}
                    value={props.details && props.details.customCategory}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customCategory: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}

              <Col md={4}>
                <b>Status</b>
                <FormSelect
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      status: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.status}
                >
                  <option value="" disabled selected>
                    Select the Status
                  </option>
                  <option value="Possession">Possession</option>
                  <option value="Non-Possession">Non-Possession</option>
                  <option value="Developed">Developed</option>
                  <option value="Non-Developed">Non-Developed</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.status == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Status"}
                    type={"text"}
                    value={props.details && props.details.customStatus}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customStatus: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}
            </Row>

            {/* ---------------------Description--------------------------- */}
            <hr style={{ borderTop: "2px solid brown" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle
                title="House Description"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col>
                <FormTextarea
                  value={props.details && props.details.description}
                  style={{ marginTop: "1.6em" }}
                  size="lg"
                  className="mb-3"
                  placeholder={"DEscription"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      description: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid blue" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle
                title="Agent Information"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={4}>
                <b>Name</b>
                <FormSelect
                  value={props.details && props.details.agentName}
                  size="lg"
                  onChange={(e) => {
                    const selectedAgentName = e.target.value;
                    const selectedAgent = agent.find((agent) => {
                      return agent.name === selectedAgentName;
                    });

                    if (selectedAgent) {
                      var information = {
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      var obj = {
                        ...props.details,
                        agentName: selectedAgentName,
                        agentAgency: selectedAgent.agency,
                        callnow: selectedAgent.phone,
                        sendmessage: selectedAgent.phone,
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      props.setDetails(obj);
                      setAgentAgency(obj.agentAgency);
                      setPhone(obj.callnow);
                      props.setAgentDatatype(information.datatype);
                      props.setAgentImage(information.image);
                    }
                  }}
                >
                  <option value="" disabled selected>
                    Select the Agent
                  </option>
                  {agent.map((agent) => {
                    return <option value={agent.name}>{agent.name}</option>;
                  })}
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Agency</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Agent Designation"}
                  type={"text"}
                  value={agency ? agency : props.details.agentAgency}
                  // value={agency}
                />
              </Col>

              <Col md={4}>
                <b>Phone Number</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Call Now"}
                  type={"text"}
                  value={phone ? phone : props.details.callnow}
                />
              </Col>
            </Row>
          </>
        ) : props.category == "flatsForRent" ||
          props.category == "flatsForSale" ? (
          <>
            {" "}
            <hr style={{ borderTop: "2px solid black" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle title="Flat Details" className="text-sm-left p-0" />
            </Row>
            <Row>
              <Col md={3}>
                <b>Flat No.</b>
                <FormInput
                  value={props.details && props.details.plotNo}
                  size="lg"
                  className="mb-3"
                  placeholder={"House No"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      plotNo: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.showPlotNo}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      showPlotNo: !props.details.showPlotNo,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <span style={{ marginLeft: "0.5em" }}>Show House Number</span>
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <b>Floor No.</b>
                <FormInput
                  value={props.details && props.details.streetTitle}
                  size="lg"
                  className="mb-3"
                  placeholder={"Street No."}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      streetTitle: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Unit</b>
                <FormInput
                  value={props.details && props.details.unit}
                  size="lg"
                  className="mb-3"
                  placeholder={"Unit"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      unit: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Extra Land</b>
                <FormInput
                  value={props.details && props.details.extraland}
                  size="lg"
                  className="mb-3"
                  placeholder={"Extra Land"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      extraland: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Category</b>
                <FormSelect
                  size="lg"
                  value={props.details && props.details.category}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      category: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Category
                  </option>
                  <option value="General">General</option>
                  <option value="Corner">Corner</option>
                  <option value="Sun Facing">Sun Facing</option>
                  <option value="Park Facing">Park Facing</option>
                  <option value="Boulevard Avenue">Boulevard Avenue</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.category == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Category"}
                    type={"text"}
                    value={props.details && props.details.customCategory}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customCategory: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}

              <Col md={4}>
                <b>Status</b>
                <FormSelect
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      status: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.status}
                >
                  <option value="" disabled selected>
                    Select the Status
                  </option>
                  <option value="Possession">Possession</option>
                  <option value="Non-Possession">Non-Possession</option>
                  <option value="Developed">Developed</option>
                  <option value="Non-Developed">Non-Developed</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.status == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Status"}
                    type={"text"}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customStatus: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}
            </Row>
            <hr style={{ borderTop: "2px solid green" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Main Features"
                className="text-sm-left p-0"
                style={{ fontSize: "1.5em" }}
              />
            </Row>
            <Row>
              {" "}
              <Col md={4}>
                <b>Flooring</b>
                <FormSelect
                  value={props.details && props.details.flooring}
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      flooring: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Flooring
                  </option>
                  <option value="Tiles">Tiles</option>
                  <option value="Marble">Marble</option>
                  <option value="Chip">Wooden</option>
                  <option value="Cement">Cement</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Electricity Backup</b>
                <FormSelect
                  value={props.details && props.details.electricity}
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      electricity: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Backup
                  </option>
                  <option value="None">None</option>
                  <option value="UPS">UPS</option>
                  <option value="Generator">Generator</option>
                  <option value="Solar">Solar</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Col>
              <Col md={4}></Col>
              <Col md={3}>
                <b>Built in Year</b>
                <FormInput
                  value={props.details && props.details.year}
                  size="lg"
                  className="mb-3"
                  placeholder={"Built in Year"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      year: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>View</b>
                <FormInput
                  value={props.details && props.details.view}
                  size="lg"
                  className="mb-3"
                  placeholder={"View"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      view: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Parking Spaces</b>
                <FormInput
                  value={props.details && props.details.parking}
                  size="lg"
                  className="mb-3"
                  placeholder={"Parking Spaces"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      parking: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Floors</b>
                <FormInput
                  value={props.details && props.details.floors}
                  size="lg"
                  className="mb-3"
                  placeholder={"Floors"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      floors: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Furnished"
                  checked={props.details.furnished}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      furnished: !props.details.furnished,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Furnished
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  checked={props.details.windows}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      windows: !props.details.windows,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Double Glazed Windows
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.ac}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      ac: !props.details.ac,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Central Air Conditioning
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.heating}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      heating: !props.details.heating,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Central Heating
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.waste}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      waste: !props.details.waste,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Waste Disposal
                </FormCheckbox>
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid blue" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle title="Rooms" className="text-sm-left p-0" />
            </Row>
            <Row>
              <Col md={3}>
                <b>Bedrooms</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Bedrooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      bedrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.bedrooms}
                />
              </Col>
              <Col md={3}>
                <b>Bathrooms</b>
                <FormInput
                  value={props.details && props.details.bathrooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Bathrooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      bathrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Kitchens</b>
                <FormInput
                  value={props.details && props.details.kitchens}
                  size="lg"
                  className="mb-3"
                  placeholder={"Kitchens"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      kitchens: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Store Rooms</b>
                <FormInput
                  value={props.details && props.details.storerooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Store Rooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      storerooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Servant Quarter</b>
                <FormInput
                  value={props.details && props.details.servantquarter}
                  size="lg"
                  className="mb-3"
                  placeholder={"Servant Quarter"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      servantquarter: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Other Rooms</b>
                <FormInput
                  value={props.details && props.details.otherrooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Store Rooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={6} style={{ marginTop: "1.6em" }}>
                <FormCheckbox
                  label="TV Lounge or Sitting Room"
                  checked={props.details.tvlounge}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      tvlounge: !props.details.tvlounge,
                    };
                    props.setDetails(obj);
                  }}
                >
                  TV Lounge or Sitting Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Drawing Room"
                  checked={props.details.drawing}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      drawing: !props.details.drawing,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Drawing Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Dining Room"
                  checked={props.details.dining}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      dining: !props.details.dining,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Dining Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Study Room"
                  checked={props.details.study}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      study: !props.details.study,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Study Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Prayer Room"
                  checked={props.details.prayer}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      prayer: !props.details.prayer,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Prayer Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Powder Room"
                  checked={props.details.powder}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      powder: !props.details.powder,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Powder Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Gum"
                  checked={props.details.gym}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      gym: !props.details.gym,
                    };
                    props.setDetails(obj);
                  }}
                >
                  GYM
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Steam Room"
                  checked={props.details.steam}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      steam: !props.details.steam,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Steam Room
                </FormCheckbox>
              </Col>

              <Col md={3}>
                <FormCheckbox
                  label="Laundry Room"
                  checked={props.details.laundry}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      laundry: !props.details.laundry,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Laundry Room
                </FormCheckbox>
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid purple" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Business & Communications"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={4}>
                <FormCheckbox
                  label="Broadband Internet Access"
                  checked={props.details.internet}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      internet: !props.details.internet,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Broadband Internet Access
                </FormCheckbox>
              </Col>
              <Col md={4}>
                <FormCheckbox
                  label="Satellite or Cable TV Ready"
                  checked={props.details.cableTv}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      cableTv: !props.details.cableTv,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Satellite or Cable TV Ready
                </FormCheckbox>
              </Col>
              <Col md={4}>
                <FormCheckbox
                  label="Intercom"
                  checked={props.details.intercom}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      intercom: !props.details.intercom,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Intercom
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Business and Communication Facilities</b>
                <FormInput
                  value={props.details && props.details.otherBusinessFacilities}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Business and Communication Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherBusinessFacilities: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* -----------------------Community Facilities--------------------------------- */}
            <hr style={{ borderTop: "2px solid orange" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Community Features"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={3}>
                <FormCheckbox
                  label="Community Lawn or Garden"
                  checked={props.details.communityLawn}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityLawn: !props.details.communityLawn,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Lawn
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Community Swimming Pool"
                  checked={props.details.communitySwimmingPool}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communitySwimmingPool:
                        !props.details.communitySwimmingPool,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Swimming Pool
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Mosque"
                  checked={props.details.mosque}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      mosque: !props.details.mosque,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Mosque
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Comunity Gym"
                  checked={props.details.communityGym}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityGym: !props.details.communityGym,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Gym
                </FormCheckbox>
              </Col>

              <Col md={3}>
                <FormCheckbox
                  label="Kids Play Area"
                  checked={props.details.kidsArea}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      kidsArea: !props.details.kidsArea,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Kids Play Area
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Day Care Centre"
                  checked={props.details.dayCare}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      dayCare: !props.details.dayCare,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Day Care Centre
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Barbeque Area"
                  checked={props.details.bbq}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      bbq: !props.details.bbq,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Barbeque Area
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Community Centre"
                  checked={props.details.communityCenter}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityCenter: !props.details.communityCenter,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Centre
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Comunity Gym"
                  checked={props.details.medicalCenter}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      medicalCenter: !props.details.medicalCenter,
                    };
                    props.setDetails(obj);
                  }}
                >
                  First Aid or Medical Centre
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Community Facilities</b>
                <FormInput
                  value={
                    props.details && props.details.otherCommunityFacilities
                  }
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Community Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherCommunityFacilities: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ------------------------Healthcare Recreational------------------------------ */}
            <hr style={{ borderTop: "2px solid red" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Healthcare Recreational"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={3}>
                <FormCheckbox
                  label="Lawn or Garden"
                  checked={props.details.lawn}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      lawn: !props.details.lawn,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Lawn or Garden
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Swimming Pool"
                  checked={props.details.swimmingPool}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      swimmingPool: !props.details.swimmingPool,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Swimming Pool
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Sauna"
                  checked={props.details.sauna}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      sauna: !props.details.sauna,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Sauna
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Jacuzzi"
                  checked={props.details.jacuzzi}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      jacuzzi: !props.details.jacuzzi,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Jacuzzi
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Healthcare and Recreation Facilities</b>
                <FormInput
                  value={props.details && props.details.otherHealthCare}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Healthcare and Recreation Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherHealthCare: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ------------Nearby Locations & Facilities----------------------- */}
            <hr style={{ borderTop: "2px solid brown" }} />
            <Row noGutters className="page-header py-2">
              <Col lg={12}>
                <PageTitle
                  //
                  title="Nearby Locations & Facilities"
                  className="text-sm-left p-0"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <b>Nearby Schools</b>
                <FormInput
                  value={props.details && props.details.nearbySchools}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Schools"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbySchools: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Hospitals</b>
                <FormInput
                  value={props.details && props.details.nearbyHospitals}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Hospitals"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyHospitals: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Shopping Malls</b>
                <FormInput
                  value={props.details && props.details.nearbyShoppingMalls}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Shopping Malls"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyShoppingMalls: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Restaurants</b>
                <FormInput
                  value={props.details && props.details.nearbyRestaurants}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Restaurants"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyRestaurants: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Distance From Airport (KMs)</b>
                <FormInput
                  value={props.details && props.details.nearbyAirport}
                  size="lg"
                  className="mb-3"
                  placeholder={"Distance From Airport (KMs)"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyAirport: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Public Transport Service</b>
                <FormInput
                  value={props.details && props.details.nearbyPublicTransport}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Public Transport Service"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyPublicTransport: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Other Nearby Places</b>
                <FormInput
                  value={props.details && props.details.nearbyOtherPlaces}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Nearby Places"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyOtherPlaces: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ---------------------Description--------------------------- */}
            <hr style={{ borderTop: "2px solid brown" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle
                title="House Description"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col>
                <FormTextarea
                  value={props.details && props.details.description}
                  style={{ marginTop: "1.6em" }}
                  size="lg"
                  className="mb-3"
                  placeholder={"House Description"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      description: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid black" }} />
            <Row noGutters className="page-header py-2">
              <Col lg={12}>
                <PageTitle
                  title="Agent Information"
                  className="text-sm-left p-0"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <b>Name</b>
                <FormSelect
                  value={props.details.agentName}
                  size="lg"
                  onChange={(e) => {
                    const selectedAgentName = e.target.value;
                    const selectedAgent = agent.find((agent) => {
                      return agent.name === selectedAgentName;
                    });

                    if (selectedAgent) {
                      var information = {
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      var obj = {
                        ...props.details,
                        agentName: selectedAgentName,
                        agentAgency: selectedAgent.agency,
                        callnow: selectedAgent.phone,
                        sendmessage: selectedAgent.phone,
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      props.setDetails(obj);
                      setAgentAgency(obj.agentAgency);
                      setPhone(obj.callnow);
                      props.setAgentDatatype(information.datatype);
                      props.setAgentImage(information.image);
                    }
                  }}
                >
                  <option value="" disabled selected>
                    Select the Agent
                  </option>
                  {agent.map((agent) => {
                    return <option value={agent.name}>{agent.name}</option>;
                  })}
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Agency</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Agent Agency"}
                  type={"text"}
                  value={agency ? agency : props.details.agentAgency}
                />
              </Col>

              <Col md={4}>
                <b>Phone No.</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Phone No."}
                  type={"text"}
                  value={phone ? phone : props.details.callnow}
                />
              </Col>
            </Row>
          </>
        ) : props.category == "housesForRent" ||
          props.category == "housesForSale" ? (
          <>
            {" "}
            <hr style={{ borderTop: "2px solid black" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle title="House Details" className="text-sm-left p-0" />
            </Row>
            <Row>
              <Col md={3}>
                <b>House No.</b>
                <FormInput
                  value={props.details && props.details.plotNo}
                  size="lg"
                  className="mb-3"
                  placeholder={"House No"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      plotNo: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.showPlotNo}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      showPlotNo: !props.details.showPlotNo,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <span style={{ marginLeft: "0.5em" }}>Show House Number</span>
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <b>Street No.</b>
                <FormInput
                  value={props.details && props.details.streetTitle}
                  size="lg"
                  className="mb-3"
                  placeholder={"Street No."}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      streetTitle: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Unit</b>
                <FormInput
                  value={props.details && props.details.unit}
                  size="lg"
                  className="mb-3"
                  placeholder={"Unit"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      unit: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Extra Land</b>
                <FormInput
                  value={props.details && props.details.extraland}
                  size="lg"
                  className="mb-3"
                  placeholder={"Extra Land"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      extraland: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Category</b>
                <FormSelect
                  size="lg"
                  value={props.details && props.details.category}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      category: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Category
                  </option>
                  <option value="General">General</option>
                  <option value="Corner">Corner</option>
                  <option value="Sun Facing">Sun Facing</option>
                  <option value="Park Facing">Park Facing</option>
                  <option value="Boulevard Avenue">Boulevard Avenue</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.category == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Category"}
                    type={"text"}
                    value={props.details && props.details.customCategory}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customCategory: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}

              <Col md={4}>
                <b>Status</b>
                <FormSelect
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      status: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.status}
                >
                  <option value="" disabled selected>
                    Select the Status
                  </option>
                  <option value="Possession">Possession</option>
                  <option value="Non-Possession">Non-Possession</option>
                  <option value="Developed">Developed</option>
                  <option value="Non-Developed">Non-Developed</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </Col>
              {props.details.status == "Others" ? (
                <Col md={4}>
                  <FormInput
                    style={{ marginTop: "1.6em" }}
                    size="lg"
                    className="mb-3"
                    placeholder={"Custom Status"}
                    type={"text"}
                    onChange={(e) => {
                      var obj = {
                        ...props.details,
                        customStatus: e.target.value,
                      };
                      props.setDetails(obj);
                    }}
                  />
                </Col>
              ) : null}
            </Row>
            <hr style={{ borderTop: "2px solid green" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Main Features"
                className="text-sm-left p-0"
                style={{ fontSize: "1.5em" }}
              />
            </Row>
            <Row>
              {" "}
              <Col md={4}>
                <b>Flooring</b>
                <FormSelect
                  value={props.details && props.details.flooring}
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      flooring: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Flooring
                  </option>
                  <option value="Tiles">Tiles</option>
                  <option value="Marble">Marble</option>
                  <option value="Chip">Wooden</option>
                  <option value="Cement">Cement</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Electricity Backup</b>
                <FormSelect
                  value={props.details && props.details.electricity}
                  size="lg"
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      electricity: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                >
                  <option value="" disabled selected>
                    Select the Backup
                  </option>
                  <option value="None">None</option>
                  <option value="UPS">UPS</option>
                  <option value="Generator">Generator</option>
                  <option value="Solar">Solar</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </Col>
              <Col md={4}></Col>
              <Col md={3}>
                <b>Built in Year</b>
                <FormInput
                  value={props.details && props.details.year}
                  size="lg"
                  className="mb-3"
                  placeholder={"Built in Year"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      year: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>View</b>
                <FormInput
                  value={props.details && props.details.view}
                  size="lg"
                  className="mb-3"
                  placeholder={"View"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      view: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Parking Spaces</b>
                <FormInput
                  value={props.details && props.details.parking}
                  size="lg"
                  className="mb-3"
                  placeholder={"Parking Spaces"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      parking: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Floors</b>
                <FormInput
                  value={props.details && props.details.floors}
                  size="lg"
                  className="mb-3"
                  placeholder={"Floors"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      floors: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Furnished"
                  checked={props.details.furnished}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      furnished: !props.details.furnished,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Furnished
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  checked={props.details.windows}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      windows: !props.details.windows,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Double Glazed Windows
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.ac}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      ac: !props.details.ac,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Central Air Conditioning
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.heating}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      heating: !props.details.heating,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Central Heating
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Plot"
                  name="plot"
                  checked={props.details.waste}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      waste: !props.details.waste,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Waste Disposal
                </FormCheckbox>
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid blue" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle title="Rooms" className="text-sm-left p-0" />
            </Row>
            <Row>
              <Col md={3}>
                <b>Bedrooms</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Bedrooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      bedrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                  value={props.details && props.details.bedrooms}
                />
              </Col>
              <Col md={3}>
                <b>Bathrooms</b>
                <FormInput
                  value={props.details && props.details.bathrooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Bathrooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      bathrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Kitchens</b>
                <FormInput
                  value={props.details && props.details.kitchens}
                  size="lg"
                  className="mb-3"
                  placeholder={"Kitchens"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      kitchens: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Store Rooms</b>
                <FormInput
                  value={props.details && props.details.storerooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Store Rooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      storerooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Servant Quarter</b>
                <FormInput
                  value={props.details && props.details.servantquarter}
                  size="lg"
                  className="mb-3"
                  placeholder={"Servant Quarter"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      servantquarter: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={3}>
                <b>Other Rooms</b>
                <FormInput
                  value={props.details && props.details.otherrooms}
                  size="lg"
                  className="mb-3"
                  placeholder={"Store Rooms"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherrooms: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={6} style={{ marginTop: "1.6em" }}>
                <FormCheckbox
                  label="TV Lounge or Sitting Room"
                  checked={props.details.tvlounge}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      tvlounge: !props.details.tvlounge,
                    };
                    props.setDetails(obj);
                  }}
                >
                  TV Lounge or Sitting Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Drawing Room"
                  checked={props.details.drawing}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      drawing: !props.details.drawing,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Drawing Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Dining Room"
                  checked={props.details.dining}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      dining: !props.details.dining,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Dining Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Study Room"
                  checked={props.details.study}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      study: !props.details.study,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Study Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Prayer Room"
                  checked={props.details.prayer}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      prayer: !props.details.prayer,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Prayer Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Powder Room"
                  checked={props.details.powder}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      powder: !props.details.powder,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Powder Room
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Gum"
                  checked={props.details.gym}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      gym: !props.details.gym,
                    };
                    props.setDetails(obj);
                  }}
                >
                  GYM
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Steam Room"
                  checked={props.details.steam}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      steam: !props.details.steam,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Steam Room
                </FormCheckbox>
              </Col>

              <Col md={3}>
                <FormCheckbox
                  label="Laundry Room"
                  checked={props.details.laundry}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      laundry: !props.details.laundry,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Laundry Room
                </FormCheckbox>
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid purple" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Business & Communications"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={4}>
                <FormCheckbox
                  label="Broadband Internet Access"
                  checked={props.details.internet}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      internet: !props.details.internet,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Broadband Internet Access
                </FormCheckbox>
              </Col>
              <Col md={4}>
                <FormCheckbox
                  label="Satellite or Cable TV Ready"
                  checked={props.details.cableTv}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      cableTv: !props.details.cableTv,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Satellite or Cable TV Ready
                </FormCheckbox>
              </Col>
              <Col md={4}>
                <FormCheckbox
                  label="Intercom"
                  checked={props.details.intercom}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      intercom: !props.details.intercom,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Intercom
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Business and Communication Facilities</b>
                <FormInput
                  value={props.details && props.details.otherBusinessFacilities}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Business and Communication Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherBusinessFacilities: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* -----------------------Community Facilities--------------------------------- */}
            <hr style={{ borderTop: "2px solid orange" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Community Features"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={3}>
                <FormCheckbox
                  label="Community Lawn or Garden"
                  checked={props.details.communityLawn}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityLawn: !props.details.communityLawn,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Lawn
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Community Swimming Pool"
                  checked={props.details.communitySwimmingPool}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communitySwimmingPool:
                        !props.details.communitySwimmingPool,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Swimming Pool
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Mosque"
                  checked={props.details.mosque}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      mosque: !props.details.mosque,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Mosque
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Comunity Gym"
                  checked={props.details.communityGym}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityGym: !props.details.communityGym,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Gym
                </FormCheckbox>
              </Col>

              <Col md={3}>
                <FormCheckbox
                  label="Kids Play Area"
                  checked={props.details.kidsArea}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      kidsArea: !props.details.kidsArea,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Kids Play Area
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Day Care Centre"
                  checked={props.details.dayCare}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      dayCare: !props.details.dayCare,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Day Care Centre
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Barbeque Area"
                  checked={props.details.bbq}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      bbq: !props.details.bbq,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Barbeque Area
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Community Centre"
                  checked={props.details.communityCenter}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      communityCenter: !props.details.communityCenter,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Community Centre
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Comunity Gym"
                  checked={props.details.medicalCenter}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      medicalCenter: !props.details.medicalCenter,
                    };
                    props.setDetails(obj);
                  }}
                >
                  First Aid or Medical Centre
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Community Facilities</b>
                <FormInput
                  value={
                    props.details && props.details.otherCommunityFacilities
                  }
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Community Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherCommunityFacilities: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ------------------------Healthcare Recreational------------------------------ */}
            <hr style={{ borderTop: "2px solid red" }} />
            <Row noGutters className="page-header py-2">
              <PageTitle
                title="Healthcare Recreational"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col md={3}>
                <FormCheckbox
                  label="Lawn or Garden"
                  checked={props.details.lawn}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      lawn: !props.details.lawn,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Lawn or Garden
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Swimming Pool"
                  checked={props.details.swimmingPool}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      swimmingPool: !props.details.swimmingPool,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Swimming Pool
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Sauna"
                  checked={props.details.sauna}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      sauna: !props.details.sauna,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Sauna
                </FormCheckbox>
              </Col>
              <Col md={3}>
                <FormCheckbox
                  label="Jacuzzi"
                  checked={props.details.jacuzzi}
                  onChange={() => {
                    const obj = {
                      ...props.details,
                      jacuzzi: !props.details.jacuzzi,
                    };
                    props.setDetails(obj);
                  }}
                >
                  Jacuzzi
                </FormCheckbox>
              </Col>
              <Col md={6}>
                <b>Other Healthcare and Recreation Facilities</b>
                <FormInput
                  value={props.details && props.details.otherHealthCare}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Healthcare and Recreation Facilities"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      otherHealthCare: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ------------Nearby Locations & Facilities----------------------- */}
            <hr style={{ borderTop: "2px solid brown" }} />
            <Row noGutters className="page-header py-2">
              <Col lg={12}>
                <PageTitle
                  //
                  title="Nearby Locations & Facilities"
                  className="text-sm-left p-0"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <b>Nearby Schools</b>
                <FormInput
                  value={props.details && props.details.nearbySchools}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Schools"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbySchools: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Hospitals</b>
                <FormInput
                  value={props.details && props.details.nearbyHospitals}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Hospitals"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyHospitals: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Shopping Malls</b>
                <FormInput
                  value={props.details && props.details.nearbyShoppingMalls}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Shopping Malls"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyShoppingMalls: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Restaurants</b>
                <FormInput
                  value={props.details && props.details.nearbyRestaurants}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Restaurants"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyRestaurants: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Distance From Airport (KMs)</b>
                <FormInput
                  value={props.details && props.details.nearbyAirport}
                  size="lg"
                  className="mb-3"
                  placeholder={"Distance From Airport (KMs)"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyAirport: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Nearby Public Transport Service</b>
                <FormInput
                  value={props.details && props.details.nearbyPublicTransport}
                  size="lg"
                  className="mb-3"
                  placeholder={"Nearby Public Transport Service"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyPublicTransport: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
              <Col md={4}>
                <b>Other Nearby Places</b>
                <FormInput
                  value={props.details && props.details.nearbyOtherPlaces}
                  size="lg"
                  className="mb-3"
                  placeholder={"Other Nearby Places"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      nearbyOtherPlaces: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            {/* ---------------------Description--------------------------- */}
            <hr style={{ borderTop: "2px solid brown" }} />
            <Row noGutters className="page-header py-1">
              <PageTitle
                title="House Description"
                className="text-sm-left p-0"
              />
            </Row>
            <Row>
              <Col>
                <FormTextarea
                  value={props.details && props.details.description}
                  style={{ marginTop: "1.6em" }}
                  size="lg"
                  className="mb-3"
                  placeholder={"House Description"}
                  type={"text"}
                  onChange={(e) => {
                    var obj = {
                      ...props.details,
                      description: e.target.value,
                    };
                    props.setDetails(obj);
                  }}
                />
              </Col>
            </Row>
            <hr style={{ borderTop: "2px solid black" }} />
            <Row noGutters className="page-header py-2">
              <Col lg={12}>
                <PageTitle
                  title="Agent Information"
                  className="text-sm-left p-0"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <b>Name</b>
                <FormSelect
                  value={props.details.agentName}
                  size="lg"
                  onChange={(e) => {
                    const selectedAgentName = e.target.value;
                    const selectedAgent = agent.find((agent) => {
                      return agent.name === selectedAgentName;
                    });

                    if (selectedAgent) {
                      var information = {
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      var obj = {
                        ...props.details,
                        agentName: selectedAgentName,
                        agentAgency: selectedAgent.agency,
                        callnow: selectedAgent.phone,
                        sendmessage: selectedAgent.phone,
                        datatype: selectedAgent.datatypeAgent,
                        image: selectedAgent.imageAgent,
                      };
                      props.setDetails(obj);
                      setAgentAgency(obj.agentAgency);
                      setPhone(obj.callnow);
                      props.setAgentDatatype(information.datatype);
                      props.setAgentImage(information.image);
                    }
                  }}
                >
                  <option value="" disabled selected>
                    Select the Agent
                  </option>
                  {agent.map((agent) => {
                    return <option value={agent.name}>{agent.name}</option>;
                  })}
                </FormSelect>
              </Col>
              <Col md={4}>
                <b>Agency</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Agent Agency"}
                  type={"text"}
                  value={agency ? agency : props.details.agentAgency}
                />
              </Col>

              <Col md={4}>
                <b>Phone No.</b>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder={"Phone No."}
                  type={"text"}
                  value={phone ? phone : props.details.callnow}
                />
              </Col>
            </Row>
          </>
        ) : null}

        {/* <Container style={{ display: "flex", flexDirection: "row" }}>
          <Col md={6}>
            <b>Image 1</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image1}
              placeholder={"Image 1"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image1: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Image 2</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image2}
              placeholder={"Image 2"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image2: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
        </Container>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <Col md={6}>
            <b>Image 3</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image3}
              placeholder={"Image 3"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image3: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Image 4</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image4}
              placeholder={"Image 4"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image4: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
        </Container>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <Col md={6}>
            <b>Image 5</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image5}
              placeholder={"Image 5"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image5: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Image 6</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image6}
              placeholder={"Image 6"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image6: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
        </Container>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <Col md={6}>
            <b>Image 7</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image7}
              placeholder={"Image 7"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image7: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Image 8</b>
            <FormInput
              size="lg"
              className="mb-3"
              value={props.details && props.details.image8}
              placeholder={"Image 8"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image8: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
        </Container>
        <Container style={{ display: "flex", flexDirection: "row" }}>
          <Col md={6}>
            <b>Image 9</b>
            <FormInput
              size="lg"
              value={props.details && props.details.image9}
              className="mb-3"
              placeholder={"Image 9"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image9: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
          <Col md={6}>
            <b>Image 10</b>
            <FormInput
              size="lg"
              value={props.details && props.details.image10}
              className="mb-3"
              placeholder={"Image 10"}
              type={"text"}
              onChange={(e) => {
                var obj = {
                  ...props.details,
                  image10: e.target.value,
                };
                props.setDetails(obj);
              }}
            />
          </Col>
        </Container> */}
      </CardBody>
    </Card>
  );
};

export default Editor;

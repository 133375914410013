// import React, { useState } from "react";
// import {
//   Button,
//   Modal,
//   ModalBody,
//   ModalHeader,
//   ModalFooter,
// } from "shards-react";
// import EditComponent from "./editComponent";

// export default class BasicModalExample extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { open: true };
//     this.toggle = this.toggle.bind(this);
//   }

//   toggle() {
//     this.props.closeModal();
//     this.props.filter();
//   }

//   render() {
//     const { open } = this.state;
//     return (
//       <div>
//         <Modal open={open} toggle={this.toggle}>
//           <ModalHeader>
//             <b>Edit Plot</b>
//             <Button outline theme='danger'  style={{marginLeft:'95em'}} onClick={this.toggle} >
//               Cancel
//             </Button>
//           </ModalHeader>
//           <ModalBody>
//             <EditComponent
//               editData={this.props.editData}
//               closeEdit={this.toggle}
//             />
//           </ModalBody>
//         </Modal>
//       </div>
//     );
//   }
// }
import React from "react";
import { Modal, Button } from "react-bootstrap";
import EditComponent from "./editComponent";
import whiteLogo from "../../images/white-logo.png";

export default class BasicModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  handleClose = () => {
    this.setState({ show: false });
    this.props.closeModal();
    this.props.filter();
  };

  render() {
    const { show } = this.state;
    return (
      <div>
        <Modal show={show} onHide={this.handleClose} fullscreen>
          <Modal.Header
            style={{
              textAlign: "center",
              justifyContent: "center",
              backgroundColor: "#ff5a3c",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "10px",
                top: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-center",
              }}
            >
              <h3
                style={{
                  marginLeft: "1em",
                  marginRight: 0,
                  marginBottom: 0,
                  marginTop: "0.4em",
                  color: "#fff",
                }}
              >
                <b>Edit Property</b> <br/>
              </h3>
            </div>

            <div>
              <img
                src={whiteLogo}
                alt="Propex Logo"
                style={{ width: "170px", height: "50px" }}
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <EditComponent
              editData={this.props.editData}
              closeEdit={this.handleClose}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

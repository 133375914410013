import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, FormInput } from "shards-react";

import SidebarActions from "../Agent/SidebarActions";

import DeleteIcon from "@mui/icons-material/Delete";

const EditComponent = (props) => {
  const [id, setId] = useState(props.editData.plotId);
  const [plotImages, setPlotImages] = useState([]);

  const fetchImageGallery = () => {
    fetch("https://property.aiodock.com/fortune/gallery/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Image Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setPlotImages(response);
      });
  };

  useEffect(() => {
    fetchImageGallery();
  }, [id]);

  const handleDelete = (_id) => {
    if (window.confirm("Are you Sure? You want to delete!")) {
      fetch(`https://property.aiodock.com/fortune/gallery/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: _id,
        }),
      }).then((res) => {
        if (res.status === 400) {
          alert("Failed, Server is not responding!");
        } else {
          alert("Success, Fortune Deleted!");
          fetchImageGallery();
        }
      });
    }
  };

  // Divide the images into groups of 4 for each row
  const rows = [];
  for (let i = 0; i < plotImages.length; i += 4) {
    const rowImages = plotImages.slice(i, i + 4);
    rows.push(rowImages);
  }

  return (
    <>
      {rows.length > 0 ? (
        rows.map((rowImages, rowIndex) => (
          <Row key={rowIndex}>
            {rowImages.map((image, index) => (
              <Col lg={3} md={3} key={image.id}>
                <div style={{ marginBottom: "2em" }}>
                  <b style={{ fontSize: "1em" }}>Image {index + 1} </b>
                  <DeleteIcon
                    style={{ color: "#c4183c", marginLeft: "10em" }}
                    onClick={() => handleDelete(image._id)}
                  />
                </div>
                <div>
                  <Card small className="card-post card-post--1">
                    <img
                      style={{ padding: "10px" }}
                      src={`https://property.aiodock.com/fortune/gallery/view/${image.image}`}
                      alt={`Slide ${index + 1}`}
                    />
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        ))
      ) : (
        "No Images are available for this Plot."
      )}
    </>
  );
};

export default EditComponent;

import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

const EditComponent = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [file, setFile] = useState(''); // Set an initial value to null
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    fetch("https://property.aiodock.com/fortune/document/" + props.editData.pdf, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 400) {
          alert("No Plot Found");
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setFile(props.editData.datatype + "base64," + response.data);
      });
  }, []);
  

  // Check if file is null before rendering
 

  return (
    <div>
        <iframe src={file} width="100%" height="800"></iframe>
            
      {/* <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
};

export default EditComponent;

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
} from "shards-react";
import ReactLoading from "react-loading";

const SidebarActions = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <ReactLoading
          type="spin"
          color={"#ff5a3c"}
          height={"20%"}
          width={"20%"}
        />
      ) : (
        <Card small className="mb-3">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{"Actions"}</h6>
          </CardHeader>

          <CardBody className="p-0">
            <ListGroup flush>
              <ListGroupItem>
                <Button
                  theme="accent"
                  size="sm"
                  style={{
                    backgroundColor: "#ff5a3c",
                    color: "#fff",
                    borderColor: "#ff5a3c",
                  }}
                  className="ml-auto"
                  onClick={() => {
                    // console.log(props);

                    setLoading(true);
                    if (props.price != undefined) {
                      if (props.file == "") {
                        alert("Please upload plot image!");
                        setLoading(false);
                        return;
                      } else if (props.details.agentName == "") {
                        alert("Please Add Agent!");
                        setLoading(false);
                        return;
                      }
                      // if (props.agentFile == "") {
                      //   alert("Please upload agent image!");
                      //   setLoading(false);
                      //   return;
                      // }
                      fetch("https://property.aiodock.com/fortune/plot", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");

                          setLoading(false);
                        } else {
                          alert("Success, Plot Published!");
                          // console.log("Agent Uploaded Successfully:", res)
                          props.reset();
                          setLoading(false);
                          window.location.href = "/add-new-plot";
                        }
                      });
                    }
                    // Gallery Image Code
                    else if (props.gallery) {
                      if (props.file == "") {
                        alert("Please upload gallery image!");
                        setLoading(false);
                        return;
                      }
                      fetch("https://property.aiodock.com/fortune/gallery", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },

                        body: JSON.stringify(props),
                      })
                        .then((res) => {
                          if (res.status == 400) {
                            alert("Failed, Server is not responding!");
                            setLoading(false);
                          } else {
                            alert("Success, Gallery Published!");
                            props.reset();
                            setLoading(false);
                            window.location.href = "/photo-gallery";
                          }
                        })
                        .catch((error) => {
                          console.error("Fetch Error:", error);
                          alert(
                            "Error: Failed to communicate with the server."
                          );
                        });
                    }
                    // ------------------------------------------------------------
                    // Home Image Code
                    else if (props.home) {
                      if (props.file == "") {
                        alert("Please upload Image!");
                        setLoading(false);
                        return;
                      }
                      fetch("https://property.aiodock.com/fortune/website", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },

                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Home Image Published!");
                          // props.reset();
                          setLoading(false);
                          window.location.reload();
                          window.location.href = "/graphics";
                        }
                      });
                    }
                    // ------------------------------------------------------------
                    // Home Image Code
                    else if (props.document) {
                      if (props.file === "") {
                        alert("Please upload Document!");
                        setLoading(false);
                        return;
                      } else if (props.title === "") {
                        alert("Please Add Document Name!");
                        setLoading(false);
                        return;
                      }
                      fetch("https://property.aiodock.com/fortune/document", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },

                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Document Uploaded Successfully!");
                          props.reset();
                          setLoading(false);
                          window.location.reload();
                          window.location.href = "/documents";
                        }
                      });
                    }
                    // ------------------------------------------------------------
                    // Add Agent
                    else if (props.agent) {
                      // setLoading(true);
                      if (props.agentFile == "") {
                        alert("Please upload Agent image!");
                        setLoading(false);
                        return;
                      } else if (props.name == "") {
                        alert("Please Add Agent Name!");
                        setLoading(false);
                        return;
                      } else if (props.phone == "") {
                        alert("Please Add Agent Phone Number!");
                        setLoading(false);
                        return;
                      } else if (props.agency == "") {
                        alert("Please Add Agent Agency!");
                        setLoading(false);
                        return;
                      }
                      fetch("https://property.aiodock.com/fortune/agent", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },

                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          setLoading(false);
                          alert("Failed, Server is not responding!");
                        } else {
                          alert("Success, Agent Registered Successfully!");
                          props.reset();
                          setLoading(false);
                          window.location.reload();
                          window.location.href = "/agent";
                        }
                      });
                    }
                    // ------------------------------------------------------------

                    // ------------------------------------------------------------
                    else if (props.configure) {
                      fetch("https://property.aiodock.com/fortune/configure", {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          id: 111,
                          youtube: {
                            ...props,
                          },
                        }),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Configure Published!");
                          props.reset();
                          setLoading(false);
                          window.location.href = "/configure";
                        }
                      });
                    } else if (props.marketwatch) {
                      fetch(
                        "https://property.aiodock.com/fortune/marketwatch",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(props),
                        }
                      ).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Market Watch Published!");
                          props.reset();
                          setLoading(false);
                          window.location.href = "/add-new-marketwatch";
                        }
                      });
                    } else if (props.sectormanagement) {
                      fetch("https://property.aiodock.com/fortune/sector", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Sector Published!");
                          props.reset();
                          setLoading(false);
                          window.location.href = "/sectormanagement";
                        }
                      });
                    } else {
                      if (props.file == "") {
                        alert("Please upload blog image!");
                        setLoading(false);
                        return;
                      }
                      fetch("https://property.aiodock.com/fortune/blog", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(props),
                      }).then((res) => {
                        if (res.status == 400) {
                          alert("Failed, Server is not responding!");
                          setLoading(false);
                        } else {
                          alert("Success, Blog Published!");
                          props.reset();
                          setLoading(false);
                          window.location.reload();
                        }
                      });
                    }
                  }}
                >
                  <i className="material-icons">file_copy</i> Publish
                </Button>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      )}
    </>
  );
};

SidebarActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

SidebarActions.defaultProps = {
  title: "Actions",
};

export default SidebarActions;

import React, { useRef } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles.css";
import "devextreme/dist/css/dx.light.css";
import LoginForm from "./views/LogIn&LogOut/SigninForm";
export default function App() {
  const login = localStorage.getItem("user");

  return (
    <>
      {login ? (
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <div>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker((props) => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                />
              );
            })}
          </div>
        </Router>
      ) : (
        <LoginForm />
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormInput,
  FormCheckbox,
} from "shards-react";

import Editor from "./components/Editor";
import SidebarActions from "./components/SidebarActions";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";

const AddNewSubService = (props) => {
  const [phase, setPhase] = useState(props.editData.phase);
  const [sector, setSector] = useState(props.editData.sector);
  const [size, setSize] = useState(props.editData.size);
  const [rate, setRate] = useState(props.editData.rate);

  const reset = () => {
    setPhase("");
    setSector("");
    setSector("");
    setRate("");
    props.closeEdit();
  };

  return (
    <Row>
      {/* Editor */}
      <Col lg="9" md="12">
        <Editor
          title={"Edit New Market Watch"}
          phase={phase}
          setPhase={setPhase}
          sector={sector}
          setSector={setSector}
          size={size}
          setSize={setSize}
          rate={rate}
          setRate={setRate}
        />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <br />
        <SidebarActions
          id={props.editData.id}
          phase={phase}
          sector={sector}
          size={size}
          rate={rate}
          reset={reset}
        />
      </Col>
    </Row>
  );
};

export default AddNewSubService;

import React, { useEffect, useState } from "react";

const EditComponent = (props) => {
  return (
    <>
      <img
        src={`https://property.aiodock.com/fortune/website/${props.editData.image}`}
        style={{
          marginTop: "1em",
          height: "100%",
          // height:'100%',
          width: "100%",
          objectFit: "contain",
        }}
      />
    </>
  );
};

export default EditComponent;

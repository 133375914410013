import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody,CardHeader, FormInput, FormCheckbox } from "shards-react";


import Editor from "./components/Editor";
import SidebarActions from "./components/SidebarActions";


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";


const AddNewSubService = (props) => {
  const [title, setTitle] = useState(props.editData.title)

  const [content,setContent]=useState(props.editData.content)

  const [icon,setIcon]=useState(props.editData.icon)

  const [service,setService]=useState(props.editData.service)

  const [openCategories,setOpenCategories]=useState(false)

  const toggle=()=>{
    setOpenCategories(!openCategories)
  }

  const [categories,setCategories]=useState([])

  const onChangeTitle = (text) => {
    setTitle(text)
  }
  const reset =()=>{
    setTitle("")
    setContent("")
    setIcon("")
    props.closeEdit()

  }
  useEffect(()=>{
    fetch('https://property.aiodock.com/fortune/service/title',{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res=>res.json()).then(response => {
        setCategories(response)
    })
  },[])


  return <Row>
      {/* Editor */}
      <Col lg="9" md="12">

        <Editor title={title} content={content} onChangeTitle={onChangeTitle} setContent={setContent}  />
      </Col>

      {/* Sidebar Widgets */}
      <Col lg="3" md="12">
        <FormInput size="lg" className="mb-3" placeholder={"Icon Name"} value={icon} onChange={(e)=>{setIcon(e.target.value)}}/>
        <Card small className="card-post card-post--1">
        <CardHeader className="border-bottom">
      <h6 className="m-0">{"Service Category"}</h6>
    </CardHeader>
        <CardBody>
                  <Dropdown open={openCategories} toggle={toggle} >
                  <DropdownToggle style={{width:"100%"}}>{service!="" ? service.split('-')[1] : "Select Category"}</DropdownToggle>
                  <DropdownMenu style={{height:"50em"}}>
                    {
                      categories.map((i)=>{
                        return <DropdownItem onClick={()=>{setService(i.id+"-"+i.title)}} >{i.title}</DropdownItem>
                      })
                    }
                  </DropdownMenu>
                </Dropdown>
        </CardBody>
         
        </Card>
        <br />
        <SidebarActions id={props.editData.id} content={content} title={title} icon={icon} service={service} reset={reset} />
  
       

      </Col>
    </Row>
}

export default AddNewSubService;


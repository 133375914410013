import React, { useState } from "react";
import { Card, CardBody, Form, FormInput, Row, Col } from "shards-react";

import InnerEditor from "./innerEditor";

import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";

const Editor = (props) => {
  const [description, setDescription] = useState("");
  const maxDescriptionLength = 50;

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxDescriptionLength) {
      setDescription(text);
      props.onChangeDescription(text);
    }
  };
  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          <Row>
            <Col lg={3}>
              <b>Author Name</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Author Name"}
                value={props.author}
                onChange={(e) => {
                  props.onChangeAuthor(e.target.value);
                }}
              />
            </Col>
            <Col lg={9}>
              <b>Blog Title</b>
              <FormInput
                size="lg"
                className="mb-3"
                placeholder={"Blog Title"}
                value={props.title}
                onChange={(e) => {
                  props.onChangeTitle(e.target.value);
                }}
              />
            </Col>
            <Col md={12}>
              <b>Description</b>
              <div className="description-input">
                <FormInput
                  size="lg"
                  className="form-control"
                  placeholder="Description (max 50 characters)"
                  value={props.description}
                  onChange={handleDescriptionChange}
                />
                <div className="character-count">
                  {description.length}/{maxDescriptionLength}
                </div>
              </div>
            </Col>
          </Row>
          <InnerEditor
            setContent={props.setContent}
            short={props.short}
            content={props.content}
          />
        </Form>
      </CardBody>
    </Card>
  );
};

export default Editor;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, FormInput, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Edit from "./Agent/EditAgent";
import MaterialTable from "material-table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AddNewService = () => {
  // All Sizes
  const [agent, setAgent] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);

  const filter = () => {
    fetchImageGallery();
  };
  useEffect(() => {
    fetchImageGallery();
  }, []);

  const closeModal = () => {
    setEdit(false);
  };

  // Fetching Image Gallery Information
  const fetchImageGallery = () => {
    fetch("https://property.aiodock.com/fortune/agent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setAgent(response);
      });
  };
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {edit ? (
        <Edit closeModal={closeModal} editData={editData} filter={filter} />
      ) : null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Agents Information"
          subtitle="All Agents"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <CardBody className="p-0 pb-3">
            <MaterialTable
              title="Agents"
              columns={[
                {
                  title: "Date",
                  field: "date",
                  defaultSort: "desc",
                  render: (rowData) =>
                    new Date(rowData.date).toLocaleDateString(),
                },
                { title: "Name", field: "name" },
                { title: "Agency", field: "agency" },
                { title: "Phone Number", field: "phone" },
              ]}
              data={agent}
              options={{
                headerStyle: {
                  backgroundColor: "#ff5a3c",
                  color: "#FFF",
                },
                actionsColumnIndex: -1,
                paging: false,
                // pageSize: 10,
                // pageSizeOptions: [20, 50, 100],
                search: true,
                exportButton: true,
              }}
              actions={[
                {
                  icon: () => <EditIcon style={{ color: "green" }} />,
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    setEditData(rowData);
                    setEdit(true);
                  },
                 
                },
                {
                  icon: () => <DeleteIcon style={{ color: "#c4183c" }} />,
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    if (window.confirm("Are you Sure? You want to delete!")) {
                      fetch(
                        "https://property.aiodock.com/fortune/agent/deleteAgent",
                        {
                          method: "DELETE",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            id: rowData.id,
                          }),
                        }
                      ).then((res) => {
                        if (res.status === 400) {
                          alert("Failed, Server is not responding!");
                        } else {
                          alert("Success, Fortune Deleted!");
                          filter();
                        }
                      });
                    }
                  },
                
                },
              ]}
            />
          </CardBody>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewService;
